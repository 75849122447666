import React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {Grid, Cell} from "react-foundation";
import {Link} from "react-router-dom";
import {requiredValidator} from "../form/validators";
import axiosApi from "../../api/axiosInstance";


const site_categories = [
    { value: 4, label: 'Grammar' },
    { value: 5, label: 'Primary' },
    { value: 6, label: 'PreSchool' },
    { value: 7, label: 'Secondary' },
    { value: 8, label: 'Special' },
    { value: 9, label: 'Nursery' },
    { value: 10, label: 'Preps' },
];

const site_types = [
    { value: 1, label: 'Urban' },
    { value: 2, label: 'Rural' },
];
const AddSite = () => {

    const handleSubmit = async (dataItem) => {
        const formattedData = {
            ...dataItem,
            site_category_id: dataItem.site_category_id?.value, // Send only category ID
            site_type_id: dataItem.site_type_id?.value,
            address: {
                address_1 : dataItem.address,
                postcode: dataItem.postcode,
                country_id: 238,
            },
            company_id: 1
        };
        try {
            const response = await axiosApi.post("sites", formattedData);
            console.log("Success:", response.data.data);
            alert('New School was Created with id '.response.data.id);
        } catch (error) {
            console.error("Error:", error);
        }
    };


    return (
        <Grid>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={12}>
                            <div className="padRegion">
                                <Link to={'/sites'} className="linkBack">&#11164; Back to Sites</Link>
                            </div>
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Add New School</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={12} large={6}>
                                <Form
                                    onSubmit={handleSubmit}
                                    render={(formRenderProps) => (
                                        <FormElement>
                                        <fieldset>
                                            <Field
                                                name="name"
                                                component={Input}
                                                label="Name"
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name="site_category_id"
                                                component={DropDownList}
                                                data={site_categories}
                                                textField="label"
                                                dataItemKey="value"
                                                label="Category"
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name="site_type_id"
                                                component={DropDownList}
                                                data={site_types}
                                                textField="label"
                                                dataItemKey="value"
                                                label="Type"
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                name="reference"
                                                component={Input}
                                                label="Reference"
                                            />
                                            <Field
                                                name="address"
                                                component={TextArea}
                                                label="Address"
                                                placeholder="Enter your address here..."
                                                validator={requiredValidator}
                                                style={{ marginTop: "16px" }}
                                            />
                                            <Field
                                                name="postcode"
                                                component={Input}
                                                label="Postcode"
                                            />
                                            <div  className="k-form-buttons">
                                                <Button type="submit" disabled={!formRenderProps.allowSubmit}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </fieldset>
                                        </FormElement>
                                    )}
                                />
                            </Cell>

                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
};

export default AddSite;