import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import userData from "./userData";


const AddUserDialog = ({ onClose, onAddUser }) => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    role: "",
    school_location: "",
    region: ""
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const roleOptions = [
    {name: "Admin", value: "admin"},
    {name: "HR", value: "hr"},
    {name: "School/Location", value: "school_location"},
    {name: "Regional", value: "regional"},
    {name: "Directorate", value: "directorate"},
    {name: "Guest", value: "guest"},
  ]

  const schoolLocationOptions = [
    {name: "School 1", value: "school_1"},
    {name: "School 2", value: "school_2"},
    {name: "School 3", value: "school_3"},
  ]

  const regionOptions = [
    {name: "Region 1", value: "region_1"},
    {name: "Region 2", value: "region_2"},
    {name: "Region 3", value: "region_3"},
  ]

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDropdownChange = (fieldName) => (e) => {
    setFormData({ ...formData, [fieldName]: e.target.value.value });
    console.log(e.target.value.value)
  };

  // Submit new user
  const handleSubmit = () => {
    if (!formData.name || !formData.surname || !formData.email || !formData.role) {
      alert("All fields are required!");
      return;
    }
    onAddUser(formData);
    onClose();
  };

  useEffect(() => {
    if (formData.role === "school_location") {
      setFormData((prev) => ({ ...prev, region: "" }));
    } else if (formData.role === "regional") {
      setFormData((prev) => ({ ...prev, school_location: "" }));
    } else {
      setFormData((prev) => ({ ...prev, school_location: "", region: "" }));
    }
  }, [formData.role]);

  useEffect(() => {
    console.log(formData)
  }, [formData])

  const validateForm = () => {
    // Check if all fields have values
    const allFieldsFilled = Object.values(formData).every(value => value.trim() !== "");
  
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(formData.email);
  
    return allFieldsFilled && isEmailValid;
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [formData]);  // Runs validation whenever formData changes

  return (
    <Dialog onClose={onClose} title="Add New User">
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "75%" }}>
        <Input name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
        <Input name="surname" placeholder="Surname" value={formData.surname} onChange={handleChange} />
        <Input name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
        <DropDownList
          data={roleOptions}
          textField="name"
          value={formData.role}
          onChange={handleDropdownChange("role")}
          placeholder="Select Role"
          defaultValue={"something"}
        />
        {
            formData.role === "school_location" ? 
            <DropDownList
            data={schoolLocationOptions}
            textField="name"
            value={formData.school_location}
            onChange={handleDropdownChange("school_location")}
            placeholder="Select Role"
          /> : null
        }
        {
            formData.role === "regional" ? 
            <DropDownList
            data={regionOptions}
            textField="name"
            value={formData.region}
            onChange={handleDropdownChange("region")}
            placeholder="Select Role"
          /> : null
        }
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <label style={{ marginBottom: "16px", padding: "0" }}>Guest Access</label>
                <Checkbox
                    checked={formData.role === "guest"}
                    disabled={true}
                />
            </div>
      </div>
      <DialogActionsBar>
        <Button onClick={onClose}>Cancel</Button>
        <Button themeColor="primary" onClick={handleSubmit} disabled={!isFormValid}>Add User</Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default AddUserDialog;