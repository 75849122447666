export const rows = [
    {
      rowName: "Draft Exclusion",
      rowId: "draft_exclusion",
      rowDescription: "Repair and reseal drafty windows and doors",
      calculationMethod: "Method: get total Natural Gas consumption for the reporting period (yearly) and reduce by 5% on the consumption, allow user to overide the window count (200 windows equates to 0.025%/unit [5/200)) which equates to number of windows * percentage saved.  Fro example, 200 windows @ 5% reduction with 2024 using 50,000 M3 Natural Gas, 50000 - (50000 * 0.05) = 50000 - 2500 = 47500 m3, then apply the 2024 gas conversion factor to both the 47500 & the 2500, with the 47500 m3 being the new usage and 2500 m3 value being the saving. If window count is changed, then calculate out costs (50) * window count.",
      fields: [
        {
          name: "Number of Windows/Doors",
          id: "data",
          isDropdown: false,
          dropdownValues: null,
          placeholder: "",
        },
        // {
        //   name: "Change",
        //   id: "change",
        //   isDropdown: false,
        //   dropdownValues: null,
        //   placeholder: "",
        // },
      ],
    },
    {
        rowName: "Time Clock Installation",
        rowId: "time_clock_installation",
        rowDescription: "Install time clock on the immersion heater in the kitchen DHW cylinder and set the times to reflect actual usage.",
        calculationMethod: "Method: Get previous usage for Electricity, for example 2024 being 100000 kwh, application of 2% saving would equate to 100000 - (100000 * 0.02) = 100000 - 2000 = 98000 kwh with 2000 kwh saving, apply 2024 electric conversion to each value to get total co2e for predicted usage, and for the saving per year.",
        fields: [
            {
            name: "Number of Time Clocks",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
            },
            // {
            // name: "Change",
            // id: "change",
            // isDropdown: false,
            // dropdownValues: null,
            // placeholder: "",
            // },
        ],
    },
    {
        rowName: "BMS",
        rowId: "bms",
        rowDescription: "Install BMS front end operating system and have appropriate staff trained.",
        calculationMethod: "Method: Determine which energy source is used for heating, get the total per year, for example 2024 has 50000 m3 natural gas and 100000 kwh electric, as with above, determine the values with reduction to display predicted usage,and the reduction amount for saving, 25% for the energy source on heating, and 5% on eletricity, application of the correct conversion by energy type and year.",
        fields: [
          {
            name: "BMS's Installed",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
          },
          // {
          //   name: "Change",
          //   id: "change",
          //   isDropdown: false,
          //   dropdownValues: null,
          //   placeholder: "",
          // },
        ],
      },
      {
          rowName: "Insulation for Wall Cavity",
          rowId: "wall_cavity_insulation",
          rowDescription: "Insulation of Wall Cavity",
          calculationMethod: "Method: use of the building footprint to determine the permiter of the building and multiply by 3.05m (average floor to ceiling height to get the m2 value to be used for the insulation types.  To get the perimeter value, it is the squre root of the area * 4.  An example, 36m2, sqrt 36 = 6, 6 * 4 = 24, 24 * 3.05 (height) = 73.2 m2 for the wall insulation.  We need to get a few examples of thermal deviation from no-insulation to insulated by product, and detrmine the % change in order to define the saving, and use the per m cost to outline the overall cost of change.  % change will be applied to the heating energy source.",
          fields: [
            {
              name: "m2 Filled Space",
              id: "data",
              isDropdown: true,
              dropdownValues: [
                  { name: "100m", value: 100 },
                  { name: "200m", value: 200 },
                  { name: "300m", value: 300 },
                  { name: "400m", value: 400 },
                  { name: "500m", value: 500 },
              ],
              placeholder: "",
            },
            // {
            //   name: "Change",
            //   id: "change",
            //   isDropdown: false,
            //   dropdownValues: null,
            //   placeholder: "",
            // },
          ],
        },
        {
            rowName: "Insulation for ceilings/roof",
            rowId: "ceiling_insulation",
            rowDescription: "Insulation for ceilings/roof",
            calculationMethod: "Method: we have the building footprint, given that most schools on average span 3 fllows, we need to divide down to get the per ceiling m2 and roof m2 as they will use different insulation types.  So for example, 36m2 across 3 fllows will have 2 ceilings at 12m2 each (24m2 total), and a single roof of 12 m2.  We will again need to provide a few examples of thermal deviations between no insulation and insulated by product (define % change).  This will be applked to the heat reduction, less fuel required to heat and retain heat.  Costs will be generated from productions by m.",
            fields: [
              {
                name: "m2 Roof Space",
                id: "data",
                isDropdown: true,
                dropdownValues: [
                    { name: "100m", value: 100 },
                    { name: "200m", value: 200 },
                    { name: "300m", value: 300 },
                    { name: "400m", value: 400 },
                    { name: "500m", value: 500 },
                ],
                placeholder: "",
              },
              // {
              //   name: "change",
              //   id: "change",
              //   isDropdown: false,
              //   dropdownValues: null,
              //   placeholder: "",
              // },
            ],
          },
          {
              rowName: "BMS Maintenance",
              rowId: "bms_maintenance",
              rowDescription: "Commission a full maintenance of the existing BMS system, including calibration checks on all hardware. ",
              calculationMethod: "",
              fields: [
                {
                  name: "Times checked",
                  id: "data",
                  isDropdown: false,
                  dropdownValues: null,
                  placeholder: "",
                },
                // {
                //   name: "change",
                //   id: "change",
                //   isDropdown: false,
                //   dropdownValues: null,
                //   placeholder: "",
                // },
              ],
            },
            {
              rowName: "Glazing Replacement",
              rowId: "glazing_replacement",
              rowDescription: "Replace single glazing with minimum double glazing ",
              calculationMethod: "",
              fields: [
                {
                  name: "m2 Replaced",
                  id: "data",
                  isDropdown: false,
                  dropdownValues: null,
                  placeholder: "",
                },
                // {
                //   name: "change",
                //   id: "change",
                //   isDropdown: false,
                //   dropdownValues: null,
                //   placeholder: "",
                // },
              ],
            },
  ];