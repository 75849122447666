import React, {useEffect, useState} from 'react';
import GhgSummaryReport from "../../../services/GhgSummaryReportService";

const EmissionsByScopeData = async(filters) => {
    let entryTypes = ['energy', 'transport', 'waste'];
    let years = [new Date().getFullYear()-1];

    let ghgParams = { report_groups: ['scopes', 'categories'] };

    if (filters.years) {
        years = filters.years;
    }
    if (filters.entryType && filters.entryType !== 'All') {
        entryTypes = [filters.entryType];
    }

    if (filters.site_type_id) {
        ghgParams = { ...ghgParams, site_type_id: filters.site_type_id };
    }
    if (filters.site_category_id) {
        ghgParams = { ...ghgParams, site_category_id: filters.site_category_id };
    }
    let combinedData = [];

    for (const year of years) {

        for (const entryType of entryTypes) {
            ghgParams = { ...ghgParams, entry_type: entryType };
            ghgParams = { ...ghgParams, year: year };
            try {
                const ghg_summary_report = await GhgSummaryReport(ghgParams); // Ensure we await API call
                if (ghg_summary_report) {
                    combinedData.push({ year, reportData: ghg_summary_report });
                }
            } catch (error) {
                console.error(`Error fetching report for ${year} and type ${entryType}:`, error);
            }
        }
    }
    //console.log(combinedData);

   /* const formattedReport = formatReport(combinedData);
    console.log(`Report for All years`, formattedReport);*/

    return formatReport(combinedData);

};


function formatReport(reportsArray) {
    const entryTypeNames = {
        PillarTwoVehicleType : 'Transport',
        PillarTwoEnergyType : 'Energy',
        PillarTwoEnergySubType : 'Energy',
        WasteMaterial : 'Waste'
    }
    let result = {
        "Scope 1": { categories: {}, total_kgco2e: 0, total_tco2e: 0 },
        "Scope 2": { categories: {}, total_kgco2e: 0, total_tco2e: 0 },
        "Scope 3": { categories: {}, total_kgco2e: 0, total_tco2e: 0 }
    };
    let default_totals = {
        total_kgco2e: 0,
        total_tco2e: 0
    };

    reportsArray.forEach(({year,reportData}) => {
        if (!reportData?.scopes) return;

        for (const scopeKey in reportData.scopes) {
            const scope = reportData.scopes[scopeKey];

            if (!result[scope.name]) {
                result[scope.name] = { categories: {}, total_kgco2e: 0 , total_tco2e: 0};
            }

            for (const categoryType in scope.categories) {
                let entry_type_name = entryTypeNames[categoryType];
                for (const categoryKey in scope.categories[categoryType]) {
                    const category = scope.categories[categoryType][categoryKey];
                    const summaryValues = Object.values(category.summary);
                    let categorySummary = summaryValues[0]; // Assuming one summary per category

                    if (!result[scope.name].categories[entry_type_name]) {
                        result[scope.name].categories[entry_type_name] = { subcategories: {}, total_kgco2e: 0 , total_tco2e: 0};
                    }
                    if (!result[scope.name].categories[entry_type_name].subcategories[category.name]) {
                        result[scope.name].categories[entry_type_name].subcategories[category.name] = default_totals;
                    }
                    /*if (!result[scope.name].categories[entry_type_name].subcategories[category.name].year) {
                        result[scope.name].categories[entry_type_name].subcategories[category.name].year = default_totals;
                    }*/
                    if (!result["Scope 3"].categories["Electricity(T&D)"]) {
                        result["Scope 3"].categories["Electricity(T&D)"] = { subcategories: {"Electricity(T&D)" : default_totals }, total_kgco2e: 0 , total_tco2e: 0};
                    }
                    /*if (!result["Scope 3"].categories["Electricity(T&D)"].subcategories["Electricity(T&D)"][year]) {
                        result["Scope 3"].categories["Electricity(T&D)"].subcategories["Electricity(T&D)"][year] = default_totals;
                    }*/

                    //result[scope.name].categories[entry_type_name].subcategories[category.name][year].e_kgco2e = categorySummary.e_kgco2e || 0;
                    //result[scope.name].categories[entry_type_name].subcategories[category.name][year].e_tco2e = categorySummary.e_tco2e || 0;
                    result[scope.name].categories[entry_type_name].subcategories[category.name].total_kgco2e += categorySummary.e_kgco2e || 0;
                    result[scope.name].categories[entry_type_name].subcategories[category.name].total_tco2e += categorySummary.e_tco2e || 0;
                    result[scope.name].categories[entry_type_name].total_kgco2e += categorySummary.e_kgco2e;
                    result[scope.name].categories[entry_type_name].total_tco2e += categorySummary.e_tco2e;
                    result[scope.name].total_kgco2e += categorySummary.e_kgco2e;
                    result[scope.name].total_tco2e += categorySummary.e_tco2e;
                    if(category.name === "Electricity" && categorySummary.el_tnd_kgco2e) {  //force tnd_kgco2e to scope3
                       // result["Scope 3"].categories["Electricity(T&D)"].subcategories["Electricity(T&D)"][year].e_kgco2e = categorySummary.el_tnd_kgco2e;
                        //result["Scope 3"].categories["Electricity(T&D)"].subcategories["Electricity(T&D)"][year].e_tco2e = categorySummary.el_tnd_kgco2e/1000;
                        result["Scope 3"].categories['Electricity(T&D)'].total_kgco2e += categorySummary.el_tnd_kgco2e;
                        result["Scope 3"].categories['Electricity(T&D)'].total_tco2e += categorySummary.el_tnd_tco2e;
                        result["Scope 3"].total_kgco2e += categorySummary.el_tnd_kgco2e;
                        result["Scope 3"].total_tco2e += categorySummary.el_tnd_tco2e;
                    }

                }
            }
        }
    });

    return result;
}

export default EmissionsByScopeData;