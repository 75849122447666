import { Chart, ChartTitle, ChartLegend, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, } from "@progress/kendo-react-charts";
import "hammerjs";

const CostYoY = () => {
  // Dummy data
  const costCategories = ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036"];
  const costDataSeries = [50000, 48000, 46000, 44000, 42000, 40000, 38000, 36000, 34000, 32000, 30000, 28000, 26000];
  

  return (
    <Chart style={{ height: "400px", margin: "0.5rem 0" }}>
      <ChartTitle text="Cost Year over Year" />
      <ChartLegend position="top" />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem categories={costCategories} title={{ text: "Years" }} />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem title={{ text: "Cost Saved (£)" }} />
      </ChartValueAxis>
      <ChartSeries>
        <ChartSeriesItem 
          type="line" 
          data={costDataSeries} 
          name="Output"
          markers={{ visible: true }}
        />
      </ChartSeries>
    </Chart>
  );
};

export default CostYoY;