import React, {useEffect, useState} from 'react';
import ComparativeFilter from "./comparativeFilter"
import {Grid, Cell} from "react-foundation";
import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from '@progress/kendo-react-charts';
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';
import {Link, NavLink, useNavigate, useLocation} from "react-router-dom"
import EmissionsByScopeReport from './EmissionsByScopeReport';
import EmissionsByTypeReport from './EmissionsByTypeReport';
import EmissionsByKgco2eReport from './EmissionsByKgco2eReport';
import { years, normalizationMetrics, emissionsCategories, locations } from './filterValues';
import {useSchoolCategoriesDropDown} from "./useSchoolCategoriesDropDown";
import EmissionsByScopeData from "./ghg/EmissionsByScopeData";
import EmissionsByCategoryData from "./ghg/EmissionsByCategoryData";

const currentYear = new Date().getFullYear() -1;
const ComparativeDashboard = () => {

        const schools = useSchoolCategoriesDropDown();
        const [baselineYear, setBaselineYear] = useState(2019);
        const [selectedYears, setSelectedYears] = useState([{ value: currentYear, label: String(currentYear) }]);
        const [normalizationMetric, setNormalizationMetric] = useState({ value: 'all', label: 'All' });
        const [emissionsCategory, setEmissionsCategory] = useState({ value: 'all', label: 'All' });
        const [school, setSchool] = useState({ value:0, label: 'All' });
        const [location, setLocation] = useState({ value: 0, label: 'All' });
        const [filters, setFilters] = useState({});
        const [emissionsByScopeData, setEmissionsByScopeData] = useState({});
        const [emissionsByCategoryData, setEmissionsByCategoryData] = useState({currentYear : {}});
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setLoading(true);
            const fetchScopeData = async () => {
                let scopeData = await EmissionsByScopeData(filters);
                setEmissionsByScopeData(scopeData);
            };

            fetchScopeData();

            const fetchCategoriesData = async () => {
                let categoryData = await EmissionsByCategoryData(filters);
                setEmissionsByCategoryData(categoryData);
                setLoading(false);
            };

            fetchCategoriesData();

        }, [filters]);

    //events
        const updateNormalizationMetric = (event) => {
            setNormalizationMetric(event.value);
            console.log('Selected Normalization Metric:', event.value);
        };
    
        const updateEmissionsCategory = (event) => {
            setEmissionsCategory(event.value);
            console.log('Selected Emissions Category:', event.value);
        };

        const updateSchool = (event) => {
            setSchool(event.value);
            console.log('Selected School Category:', event.value);
          }; 
          
          const updateLocation = (event) => {
            setLocation(event.value);
            console.log('Selected Location Category:', event.value);
          };         
    
        const updateYear = (event) => {
            const selected = event.target.value;
          
            const updatedYears = [
              ...selected,
              { value: currentYear, label: currentYear },
            ];
          
            const uniqueYears = Array.from(
              new Set(updatedYears.map((year) => year.value))
            ).map((value) => years.find((year) => year.value === value));
          
            setSelectedYears(uniqueYears);
          };
        const applyFilters = () => {
            const yearsArray = selectedYears.map(item => item.value);
            let seletedFilters = {
                entry_type : emissionsCategory.value,
                site_category_id : school.value,
                site_type_id : location.value,
                years : yearsArray
            }
            setFilters(seletedFilters);
        };

    return (
        <div style={{margin: '2rem 2rem 0 2rem', padding: '1rem'}}>
        <ComparativeFilter
                year={selectedYears}
                normalizationMetric={normalizationMetric}
                emissionsCategory={emissionsCategory}
                years={years}
                school={school}
                location={location}
                normalizationMetrics={normalizationMetrics}
                emissionsCategories={emissionsCategories}
                schools={schools}
                locations={locations}
                updateYear={updateYear}
                updateNormalizationMetric={updateNormalizationMetric}
                updateEmissionsCategory={updateEmissionsCategory}
                updateSchool={updateSchool}
                updateLocation={updateLocation}
                applyFilters = {applyFilters}
                isLoading={loading}
        />

        <Grid className="display" style={{backgroundColor: '#ffffff', padding: '1rem', margin: '2rem 0', borderRadius: '3px'}}>
            <Cell small={12} large={6} style={{padding: '0 0.5rem'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0'}}>
                    <span>Emissions By Scope</span>
                    <Link to={'/emissions-by-scope'} className="nav-link">View</Link>
                </div>
                <Card>
                    <EmissionsByScopeReport reportData={emissionsByScopeData} />
                </Card>
            </Cell>
            <Cell small={12} large={6} style={{padding: '0 0.5rem'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0'}}>
                    <span>Emissions By Type</span>
                    <Link to={'/emissions-by-type'} className="nav-link">View</Link>
                </div>
                <Card>
                    <EmissionsByTypeReport reportData={emissionsByCategoryData[currentYear]}/>
            </Card>
            </Cell>
            <Cell small={12} large={12} style={{padding: '0 0.5rem'}}>
                <Card>
                    <EmissionsByKgco2eReport reportData={emissionsByCategoryData}/>
                </Card>
            </Cell>
        </Grid>
        </div>
    )
}

export default ComparativeDashboard