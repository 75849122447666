import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { gaugeRadialIcon, gearsIcon, globeIcon, dataIcon, eyeIcon, folderIcon, menuIcon, homeIcon, linkIcon } from '@progress/kendo-svg-icons';
const items = [{
  text: 'Home',
  svgIcon: homeIcon,
  route: '/'
},{
    text: 'Ghg',
    svgIcon: gaugeRadialIcon,
    route: '/ghg-dashboard'
}, {
  text: 'Sites',
  svgIcon: dataIcon,
  route: '/sites'
}, {
    text: 'Company Targets',
    svgIcon: linkIcon,
    route: '/company-targets'
}, {
    text: 'Assets',
    svgIcon: folderIcon,
    route: '/asset/import'
}, {
  text: 'Transport-Sites',
  svgIcon: globeIcon,
  route: '/workshops'
}, {
    text: 'Predictive',
    svgIcon: eyeIcon,
    route: '/predictive-reports'
}, {
    text: 'Help',
    svgIcon: gearsIcon,
    route: '/help'
  },];
const DrawerRouterContainer = props => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(true);
  const [selected, setSelected] = React.useState(items.findIndex(x => x.selected === true));
  const handleClick = () => {
    setExpanded(!expanded);
  };
  const onSelect = e => {
    navigate(e.itemTarget.props.route);
    setSelected(e.itemIndex);
  };
  const navHeight = {
    height: "100vh"
  }
  const logoStyle = {
    width: "150px",
    marginLeft: "0.5rem"
  }
  const hamburgStyle = {
    padding: "1rem 0.5rem",
    backgroundColor: "#1d2233",
  }

  return <div>
            <div className="custom-toolbar" style={hamburgStyle}>
                <Button svgIcon={menuIcon} fillMode="flat" onClick={handleClick} />
                {
                    expanded ?
                    <Link to='/' className="mail-box"><img style={logoStyle} src="/images/Logo_White-01.png" alt="SustainIQ Company Logo" /></Link> : null
                }
            </div>
            <Drawer expanded={expanded} position={'start'} mode={'push'} mini={true} items={items.map((item, index) => ({
      ...item,
      selected: index === selected
    }))} onSelect={onSelect}>
                <DrawerContent style={navHeight}>{props.children}</DrawerContent>
            </Drawer>
        </div>;
};
export default DrawerRouterContainer;