import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import axiosApi from "../../api/axiosInstance";
import React, {useState} from "react";
import ManageNavbar from "./Navbar";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import FileImportForm from "./FileImportForm";
// import * as React from "@types/react";

const SitesImport = () => {

    return (
        <Grid>
            <ManageNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Sites Upload Input</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={6} large={6}>
                                <div className="quickSelectTab">
                                    <h4>Sites Upload</h4>
                                    <hr/>
                                    <FileImportForm upload_api_url = '/sites/upload'/>
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default SitesImport;