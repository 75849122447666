import axios from "axios";

const API_URL = "https://api-eaproduction.sustainiq.co.uk/api/v1";
//const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
        baseURL: API_URL,
        headers: {
                "Content-Type": "application/json",
        },
});

export default axiosApi;