import React, { useState, useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";


const EditUserDialog = ({ selectedUser, onClose, onSave }) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [editedUser, setEditedUser] = useState({ ...selectedUser });
  
    const roleOptions = [
      {name: "Admin", value: "admin"},
      {name: "HR", value: "hr"},
      {name: "School/Location", value: "school_location"},
      {name: "Regional", value: "regional"},
      {name: "Directorate", value: "directorate"},
      {name: "Guest", value: "guest"},
    ]

    const schoolLocationOptions = [
      {name: "School 1", value: "school_1"},
      {name: "School 2", value: "school_2"},
      {name: "School 3", value: "school_3"},
    ]

    const regionOptions = [
      {name: "Region 1", value: "region_1"},
      {name: "Region 2", value: "region_2"},
      {name: "Region 3", value: "region_3"},
    ]
  
    // Handle input changes
    const handleChange = (field) => (e) => {
      setEditedUser({ ...editedUser, [field]: e.target.value });
    };
  
    const handleDropdownChange = (fieldName) => (e) => {
      setEditedUser({ ...editedUser, [fieldName]: e.target.value || "" }); // Ensure a string value
    };

    const validateForm = () => {
      // Ensure required fields are filled
      const requiredFields = ["name", "surname", "email", "role"];
      const allFieldsFilled = requiredFields.every(
        (field) => editedUser[field] && String(editedUser[field]).trim() !== ""
      );
    
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isEmailValid = emailRegex.test(editedUser.email || "");
    
      return allFieldsFilled && isEmailValid;
    };

    useEffect(() => {
      setIsFormValid(validateForm());
      console.log(editedUser)
    }, [editedUser]);  
    
    if (!selectedUser) return null;

    return (
      <Dialog onClose={onClose}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "75%" }}>
          <Input value={editedUser.name} onChange={handleChange("name")} />
          <Input value={editedUser.surname} onChange={handleChange("surname")} />
          <Input value={editedUser.email} onChange={handleChange("email")} />
        <DropDownList
          data={roleOptions}
          textField="name"
          value={roleOptions.find(option => option.value === editedUser.role) || null}
          onChange={handleDropdownChange("role")}
          placeholder="Select Role"
          defaultValue={"something"}
        />
        {
            editedUser.role === "school_location" ? 
            <DropDownList
            data={schoolLocationOptions}
            textField="name"
            value={editedUser.school_location}
            onChange={handleDropdownChange("school_location")}
            placeholder="Select Role"
          /> : null
        }
        {
            editedUser.role === "regional" ? 
            <DropDownList
            data={regionOptions}
            textField="name"
            value={editedUser.region}
            onChange={handleDropdownChange("region")}
            placeholder="Select Role"
          /> : null
        }
        </div>
        <DialogActionsBar>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={() => onSave(editedUser)} disabled={!isFormValid}>Save</Button>
        </DialogActionsBar>
      </Dialog>
    );
};

export default EditUserDialog;