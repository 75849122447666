import {parseNumber}  from "../form/format-helper";

function getEmissions(ghg_emissions,key='amount') {
    let result = [];

    for (let j = 0; j < ghg_emissions.length; j++) {
        let ghg_emission = ghg_emissions[j];
        if (ghg_emission['measurement'] === 'Kilowatt Hour (Gross CV)' && ghg_emission['belongs_to_measurement'] === 'kgCO2') {
            result['kwh'] = parseNumber(ghg_emission[key]);
        } else if (ghg_emission['measurement'] === 'kgCO2') {
            result['kgco2'] = parseNumber(ghg_emission[key]);
        } else if (ghg_emission['measurement'] === 'kgCO2e') {
            result['kgco2e'] = parseNumber(ghg_emission[key]);
            result['tco2e'] = parseNumber(ghg_emission[key] / 1000);
        }
    }

    return result;
}

function getCenterCodesByMeter(codes) {
    let result = [];
    for (let meter_id in codes){
        let entry = codes[meter_id];
        result[meter_id] =[];
        for(let code_type in entry['codes']){
           result[meter_id][code_type] = entry['codes'][code_type];
        }
    }
    return result;
}

export function FormatEnergyEntries(data, chart=true)
{
    const energy_series = [];
    const energy_series_by_code = [];
    const energy_series_api = [];
    const total_summary_by_code=[];
    const total_summary = [];
    const temperature_series = [];

    let meterEntries = [];
    let result = [];
    let entries = data['list'];


    if (entries.length > 0) {

        let centre_codes =  (chart === true) ? data['summary']['centre_codes'] : data['centre_codes']
        const codes = getCenterCodesByMeter(centre_codes);

        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i];
            let kwh_amount = entry['energy_type']['id'] === 1 ? entry['amount'] : entry['c_kwh_gross_cv'];
            let emissions = getEmissions(entry['ghg_emissions']);
            meterEntries.push({
                "Meter": entry['meter_name'],
                "Site" : entry['site_name'],
                "EnergyType": entry['energy_type']['name'],
                "Date": entry['added_on'],
                "Amount": entry['amount'],
                "kgCO2eTotal": emissions['kgco2e'],
                "tCO2eTotal": emissions['tco2e'],
                "Kwh": kwh_amount,
                "BillType" : entry['bill_estimate'],
                "InputType" : entry['source_type']['name'],
                "Kitchen" : codes[entry['meter_id']] ? (codes[entry['meter_id']]['Kitchen'] ? entry['amount']*codes[entry['meter_id']]['Kitchen']/100 : 0) : 0,
                "School" : codes[entry['meter_id']] ? (codes[entry['meter_id']]['School'] ? entry['amount']*codes[entry['meter_id']]['School']/100 : 0) : 0,
                "General" : codes[entry['meter_id']] ? (codes[entry['meter_id']]['General'] ? entry['amount']*codes[entry['meter_id']]['General']/100 : 0) : 0,
            });
        }


        if(chart === true) {

            let summary = data['summary'];

            if (summary['by_energy_type']) {
                let entry = summary['by_energy_type'][0];
                let emissions = getEmissions(entry['ghg_emissions'], 'total_amount');
                total_summary['amount'] = parseNumber(entry['total_amount'] );
                total_summary['kwh'] = parseNumber(entry['total_kwh_amount']);
                total_summary['m3'] = parseNumber(entry['total_m3_amount']);
                total_summary['ltr'] = parseNumber(entry['total_ltr_amount']);
                total_summary['kgco2'] = parseNumber(emissions['kgco2']);
                total_summary['kgco2e'] = parseNumber(emissions['kgco2e']);
                total_summary['tco2e'] = parseNumber(emissions['tco2e']);
            }

            if (summary['by_month']) {
                let months = [];
                let chart_data = [];
                let avg = 0;
                for (let i = 0; i < summary['by_month'].length; i++) {
                    let entry = summary['by_month'][i];
                    let color = (entry['bill_estimate_flag'] === 1) ? '#ff4e50' : '#37b400'; // 1 is for estimate 0 for actual

                    let month = entry['added_month']
                    month = months.includes(month) ? month + '-0' + Math.floor(Math.random() * 9) : month;
                    months.push(month)
                    avg = avg + entry['amount'];
                    chart_data.push({
                        value: parseNumber(entry['amount']),
                        color: color,
                        category: month
                    });
                }
                energy_series.push({
                    data: chart_data,
                    avg: avg/summary['by_month'].length
                })
            }

            if (summary['by_day_api']) {
                let chart_data = [];
                let avg = 0;
                for (let i = 0; i < summary['by_day_api'].length; i++) {
                    let entry = summary['by_day_api'][i];
                     avg = avg + entry['amount'];
                    let color = '#37b400'; // 1 is for estimate 0 for actual
                    if(new Date(entry['added_on']).getDay()%6 === 0 ) { color = '#FAD02C' }
                    chart_data.push({
                        value: parseNumber(entry['amount']),
                        category: entry['added_on'],
                        color: color
                    });
                }
                energy_series_api.push({
                    data: chart_data,
                    avg: avg/summary['by_day_api'].length
                })
            }

            if (summary['by_centre_codes_month']) {
                for (let codeEntry of summary['by_centre_codes_month']) {
                    let name = codeEntry['code_name'];
                    let chart_data = [];
                    for (let month in codeEntry['list']) {
                        let codeList = codeEntry['list'][month];
                        chart_data.push({
                            value: parseNumber(codeList['amount']),
                            category: codeList['date']
                        })
                    }
                    energy_series_by_code.push({
                        name: name,
                        data: chart_data
                    });
                }
                //console.log(energy_series_by_code);
            }

            if (summary['by_energy_meter']['overall']) {

                for (let codeName in summary['by_energy_meter']['overall']) {
                    let codeSummary = [];
                    let entry = summary['by_energy_meter']['overall'][codeName];
                    let emissions = getEmissions(entry['ghg_emissions'], 'total_amount');
                    codeSummary = {
                        name: codeName,
                        amount: parseNumber(entry['total_amount']),
                        kwh: parseNumber(entry['total_kwh_amount']),
                        kgco2: parseNumber(emissions['kgco2']),
                        kgco2e: parseNumber(emissions['kgco2e']),
                        tco2e: parseNumber(emissions['tco2e'])
                    };
                    total_summary_by_code.push(codeSummary);
                }//console.log(totalSummary);
            }

            //Temperature chart
            if (summary['by_temperature']) {
                let entry = summary['by_temperature'];

                let indoorData = [];
                let outdoorData = [];
                for (let day in entry['by_day']) {
                    let dayEntry = entry['by_day'][day];
                    indoorData.push({
                        value: dayEntry['indoor'],
                        category: day
                    });
                    outdoorData.push({
                        value: dayEntry['outdoor'],
                        category: day
                    })
                }
                temperature_series['chart'] = [];
                temperature_series['chart'].push({
                    name : 'indoor',
                    data : indoorData
                });
                temperature_series['chart'].push({
                    name : 'outdoor',
                    data : outdoorData
                });
                temperature_series['u-value'] =  entry['uValue'];
                temperature_series['heat-loss'] =  entry['het_loss_m2'];
            }

        }
    }
    result['energy_chart_data'] = energy_series;
    result['energy_chart_api_data'] = energy_series_api;
    result['energy_chart_codes_data'] = energy_series_by_code;
    result['total_summary_by_code'] = total_summary_by_code;
    result['total_summary'] = total_summary;
    result['energy_table_data'] = meterEntries;
    result['temperature_series'] = temperature_series;
    //console.log(result);
    return result;

}