import React, { useEffect, useState } from 'react';
import { MultiSelect, DropDownList } from "@progress/kendo-react-dropdowns"
import { Button } from "@progress/kendo-react-buttons";


const ComparativeFilter = ({
    year,
    normalizationMetric,
    emissionsCategory,
    years,
    normalizationMetrics,
    emissionsCategories,
    updateYear,
    updateNormalizationMetric,
    updateEmissionsCategory,
    school,
    schools,
    updateSchool,
    location,
    locations,
    updateLocation,
    applyFilters,
    isLoading
}) => {

    return (
        <div>
            <MultiSelect
                data={years}
                onChange={updateYear}
                value={year}
                textField="label"
                dataValueField="value"
                placeholder="Year(s)"
                style={{ width: 'auto', margin: '0 0.5rem' }}
            />
            <DropDownList 
                data={normalizationMetrics}
                onChange={updateNormalizationMetric}
                value={normalizationMetric}
                textField="label"
                dataValueField="value"
                placeholder="Normalization Metric"
                style={{ width: '200px', margin: '0 0.5rem', height: '40px' }}
            />
            <DropDownList 
                data={emissionsCategories}
                onChange={updateEmissionsCategory}
                value={emissionsCategory}
                textField="label"
                dataValueField="value"
                placeholder="Emissions Category"
                style={{ width: '200px', margin: '0 0.5rem', height: '40px' }}
            />
            <DropDownList 
                data={schools}
                onChange={updateSchool}
                value={school}
                textField="label"
                dataValueField="value"
                placeholder="ESchool Type"
                style={{ width: '200px', margin: '0 0.5rem', height: '40px' }}
            />
            <DropDownList 
                data={locations}
                onChange={updateLocation}
                value={location}
                textField="label"
                dataValueField="value"
                placeholder="Location"
                style={{ width: '200px', margin: '0 0.5rem', height: '40px' }}
            />
            <Button 
            type="button"
            onClick={applyFilters}
            style={{ height: "40px", padding: "0 1rem", float: "right", margin: '0 0.5rem' }}
            disabled={isLoading}
            >
            Apply
            </Button>
            
        </div>
    )
}

export default ComparativeFilter