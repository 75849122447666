import { useState, useEffect } from "react";
import {fetchSchoolCategories} from "../../services/SiteService";

export const useSchoolCategoriesDropDown = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const getCategories = async () => {
            const data = await fetchSchoolCategories();
            setCategories(data);
        };

        getCategories();
    }, []);

    return categories;
};