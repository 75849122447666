import React, {useEffect, useState} from 'react';
import GhgSummaryReport from "../../../services/GhgSummaryReportService";

const EmissionsByTypeData = async(filters) => {
    let entryTypes = ['energy', 'transport', 'waste'];
    let years = [new Date().getFullYear()-1];

    let ghgParams = { report_groups: ['categories'] };

    if (filters.years) {
        years = filters.years;
    }
   /* if (filters.entryType && filters.entryType !== 'All') {
        entryTypes = [filters.entryType];
    }*/

    if (filters.site_type_id) {
        ghgParams = { ...ghgParams, site_type_id: filters.site_type_id };
    }
    if (filters.site_category_id) {
        ghgParams = { ...ghgParams, site_category_id: filters.site_category_id };
    }
    let combinedData = [];

    for (const year of years) {

        for (const entryType of entryTypes) {
            ghgParams = { ...ghgParams, entry_type: entryType };
            ghgParams = { ...ghgParams, year: year };
            try {
                const ghg_summary_report = await GhgSummaryReport(ghgParams); // Ensure we await API call
                if (ghg_summary_report) {
                    combinedData.push({ year, reportData: ghg_summary_report });
                }
            } catch (error) {
                console.error(`Error fetching report for ${year} and type ${entryType}:`, error);
            }
        }
    }
    //console.log(combinedData);


    return formatReport(combinedData);

};


function formatReport(reportsArray) {
    const entryTypeNames = {
        PillarTwoVehicleType : 'Transport',
        PillarTwoEnergyType : 'Energy',
        PillarTwoEnergySubType : 'Energy',
        WasteMaterial : 'Waste'
    }
    let result = {
        "Energy": { categories: {}, total_kgco2e: 0, total_tco2e: 0 },
        "Transport": { categories: {}, total_kgco2e: 0, total_tco2e: 0 },
        "Waste": { categories: {}, total_kgco2e: 0, total_tco2e: 0 }
    };

    reportsArray.forEach(({year,reportData}) => {
        if (!reportData?.categories) return;

        for (const categoryType in reportData.categories) {
            const categoryData  = reportData.categories[categoryType];
            let entry_type_name = entryTypeNames[categoryType];

            if (!result[entry_type_name]) {
                result[entry_type_name] = { categories: {}, total_kgco2e: 0 , total_tco2e: 0 };
            }


            for (const categoryKey in categoryData) {
                const category = categoryData[categoryKey];
                const summaryValues = Object.values(category.summary);
                let categorySummary = summaryValues[0]; // Assuming summary exists for the category

                // Initialize the category in the result if not already initialized
                if (!result[entry_type_name].categories[category.name]) {
                    result[entry_type_name].categories[category.name] = {
                        total_kgco2e: 0,  // Category total
                        total_tco2e: 0,  // Category total tco2e
                    };
                }
                if (category.name === "Electricity" && categorySummary.el_tnd_kgco2e) {
                    if (!result[entry_type_name].categories[category.name + "(T&D)"]) {
                        result[entry_type_name].categories[category.name + "(T&D)"] = {
                            total_kgco2e: 0,  // Category total
                            total_tgco2e: 0,  // Category total tco2e
                        };
                    }
                }

                // Combine total `e_kgco2e` across all categories by year
                result[entry_type_name].categories[category.name].total_kgco2e += categorySummary.e_kgco2e || 0;
                result[entry_type_name].categories[category.name].total_tco2e += categorySummary.e_tco2e || 0;
                result[entry_type_name].total_kgco2e += categorySummary.e_kgco2e || 0;
                result[entry_type_name].total_tco2e += categorySummary.e_tco2e || 0;
                if(category.name === "Electricity" && categorySummary.el_tnd_kgco2e) {  //force tnd_kgco2e to electricity only
                    // result["Scope 3"].categories["Electricity(T&D)"].subcategories["Electricity(T&D)"][year].e_kgco2e = categorySummary.el_tnd_kgco2e;
                    //result["Scope 3"].categories["Electricity(T&D)"].subcategories["Electricity(T&D)"][year].e_tco2e = categorySummary.el_tnd_kgco2e/1000;
                    if (!result[entry_type_name].categories[category.name + "(T&D)"]) {
                        result[entry_type_name].categories[category.name + "(T&D)"] = { total_kgco2e: 0, total_tco2e: 0 };
                    }
                    result[entry_type_name].categories[category.name + "(T&D)"].total_kgco2e += categorySummary.el_tnd_kgco2e;
                    result[entry_type_name].categories[category.name + "(T&D)"].total_tco2e += categorySummary.el_tnd_tco2e;
                    result[entry_type_name].total_kgco2e += categorySummary.el_tnd_kgco2e;
                    result[entry_type_name].total_tco2e += categorySummary.el_tnd_tco2e;
                }
            }
        }

    });

    return result;
}

export default EmissionsByTypeData;