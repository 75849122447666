const userData = [
    { id: 1, name: "Alice", surname: "Johnson", email: "alice.j@example.com", role: "Admin" },
    { id: 2, name: "Bob", surname: "Smith", email: "bob.smith@example.com", role: "Editor" },
    { id: 3, name: "Charlie", surname: "Brown", email: "charlie.b@example.com", role: "Viewer" },
    { id: 4, name: "David", surname: "White", email: "david.w@example.com", role: "Admin" },
    { id: 5, name: "Ella", surname: "Black", email: "ella.b@example.com", role: "Editor" },
    { id: 6, name: "Frank", surname: "Davis", email: "frank.d@example.com", role: "Viewer" },
    { id: 7, name: "Grace", surname: "Moore", email: "grace.m@example.com", role: "Admin" },
    { id: 8, name: "Henry", surname: "Wilson", email: "henry.w@example.com", role: "Editor" },
    { id: 9, name: "Ivy", surname: "Taylor", email: "ivy.t@example.com", role: "Viewer" },
    { id: 10, name: "Jack", surname: "Anderson", email: "jack.a@example.com", role: "Admin" },
    { id: 11, name: "Kate", surname: "Thomas", email: "kate.t@example.com", role: "Editor" },
    { id: 12, name: "Liam", surname: "Martinez", email: "liam.m@example.com", role: "Viewer" },
    { id: 13, name: "Mia", surname: "Harris", email: "mia.h@example.com", role: "Admin" },
    { id: 14, name: "Noah", surname: "Clark", email: "noah.c@example.com", role: "Editor" },
    { id: 15, name: "Olivia", surname: "Lewis", email: "olivia.l@example.com", role: "Viewer" },
    { id: 16, name: "Peter", surname: "Hall", email: "peter.h@example.com", role: "Admin" },
    { id: 17, name: "Quinn", surname: "Young", email: "quinn.y@example.com", role: "Editor" },
    { id: 18, name: "Ryan", surname: "King", email: "ryan.k@example.com", role: "Viewer" },
    { id: 19, name: "Sophia", surname: "Scott", email: "sophia.s@example.com", role: "Admin" },
    { id: 20, name: "Thomas", surname: "Adams", email: "thomas.a@example.com", role: "Editor" },
    { id: 21, name: "Uma", surname: "Nelson", email: "uma.n@example.com", role: "Viewer" },
    { id: 22, name: "Victor", surname: "Carter", email: "victor.c@example.com", role: "Admin" },
    { id: 23, name: "Wendy", surname: "Mitchell", email: "wendy.m@example.com", role: "Editor" },
    { id: 24, name: "Xander", surname: "Perez", email: "xander.p@example.com", role: "Viewer" },
    { id: 25, name: "Yasmine", surname: "Roberts", email: "yasmine.r@example.com", role: "Admin" },
  ];
  
  export default userData;
