export const scope_1_transport = [
    {
      rowName: "Alternative Fuel",
      rowId: "alternative_fuel",
      rowDescription: "Switch from Diesel to HVO",
      calculationMethod: "Use known usage values (miles travelled per year), prediction will switch the current fuel source of diesel to Biofuel instead, suse of yearly conversion factors for diesel/mile, apply to HVO/mile instead, apply conversion factor ro get predicted usage with co2e for HVO, and then deduct HVO co2e from diesel co2e to get he saving.",
      fields: [
        {
          name: "No. Vehicles",
          id: "data",
          isDropdown: false,
          dropdownValues: null,
          placeholder: "",
        },
      ],
    },
  ];