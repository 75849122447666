import React, {useEffect, useState} from 'react';
import ComparativeAccordion from './ComparativeAccordion';
import ComparativeTable from './comparativeTable';
import {Grid, Cell} from "react-foundation";
import ComparativeFilter from './comparativeFilter';
import ComparativePie from './comparativePie';
import { scope1Data, companyOwnedControlledFacilities } from './tableData';
import { pieData, lineData } from './chartData';
import { years, normalizationMetrics, emissionsCategories,locations } from './filterValues';
import {useSchoolCategoriesDropDown} from "./useSchoolCategoriesDropDown";
import EmissionsByScopeData from "./ghg/EmissionsByScopeData";
import EmissionsByTypeData from "./ghg/EmissionsByTypeData";

// AccordionItem Component
const currentYear = new Date().getFullYear() -1;
const EmissionsByType = () => {

    //state variables
    //const [baselineYear, setBaselineYear] = useState('2019');
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const [selectedYears, setSelectedYears] = useState([{ value: currentYear, label: String(currentYear) }]);
    const [normalizationMetric, setNormalizationMetric] = useState({ value: 'number_of_staff', label: 'Number of Staff' });
    const [emissionsCategory, setEmissionsCategory] = useState({ value: 'all', label: 'All' });
    const schools = useSchoolCategoriesDropDown();
    const [school, setSchool] = useState({ value: 0, label: 'All' });
    const [location, setLocation] = useState({ value: 0, label: 'All' });

    const [tableData, setTableData] = useState(scope1Data);
    const [reportType, setReportType] = useState('donut');
    const [reportData, setReportData] = useState([]);

    const [filters, setFilters] = useState({});
    const [emissionsByTypeData, setEmissionsByTypeData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchTypeData = async () => {
            let typeData = await EmissionsByTypeData(filters);
            setEmissionsByTypeData(typeData);
            setLoading(false);
            const overallTotal = Object.values(emissionsByTypeData)
                .reduce((total, data) => total + (data.total_kgco2e || 0), 0);

            // Generate pieData1 based on the emissions data
            const pieData1 = Object.entries(emissionsByTypeData).map(([entryType, data]) => {
                const typeTotal = data.total_kgco2e || 0;
                const share = overallTotal > 0 ? (typeTotal / overallTotal) : 0; // Calculate share

                return {
                    name: entryType,
                    share: share,
                    total_kgco2e: typeTotal.toFixed(2) // Store the total emissions for each type
                };
            });
            setReportData(pieData1);
        };

        fetchTypeData();


    }, [filters]);

    const typeTitleIcons = {
        "Energy" : {
            content : "",
            icon : "https://api.iconify.design/material-symbols:counter-1-outline.svg",
        },
        "Transport" : {
            content : "Emissions that your company causes indirectly when the energy it purchases and uses is produced",
            icon : "https://api.iconify.design/material-symbols:counter-2-outline.svg",
        },
        "Waste" : {
            content : "Emissions your company is indirectly responsible for, up and down the value chain",
            icon : "https://api.iconify.design/material-symbols:counter-3-outline.svg"
        },
    }

    const accordionItems = Object.entries(emissionsByTypeData).map(([entryType, data]) => {
        const totalKgCo2eForType = data.total_kgco2e || 0;

        // Generate pieData2 for the entire entryType (Energy, Transport, etc.)
        const pieData2 = Object.entries(data.categories).map(([category, values]) => {
            const totalKgCo2eForCategory = values.total_kgco2e || 0;

            // Calculate the share for the category in the pie chart (proportion of total)
            const share = totalKgCo2eForType > 0 ? (totalKgCo2eForCategory / totalKgCo2eForType) : 0;

            return {
                name: category,
                share: share
            };
        });

        return {
            title: entryType,
            content: typeTitleIcons[entryType].content,
            icon: typeTitleIcons[entryType].icon,
            kgco2e: (data.total_kgco2e || 0).toFixed(2),
            reportData: pieData2,
            scopeOptions: Object.entries(data.categories).map(([category, values]) => ({
                name:  category,
                kgco2e: (values.total_kgco2e || 0).toFixed(2),
                handleTableUpdate: () => handleTableUpdate(companyOwnedControlledFacilities, 'donut', pieData2)
            }))
        };
    });

    const tableHeadings = [
        {field: 'type', title: 'Type'},
        {field: 'unit', title: 'Unit'},
        {field: 'total', title: 'Total'},
        {field: 'emission_type', title: 'Emission Type'},
        {field: 'tco2e', title: 'tCO2e'},
        {field: 'percent_total_year_co2', title: '% of Total Year CO2'},
        {field: 'per_FTE', title: 'Per FTE'}
    ];

    //events
    const updateNormalizationMetric = (event) => {
        setNormalizationMetric(event.value);
        console.log('Selected Normalization Metric:', event.value);
    };

    const updateEmissionsCategory = (event) => {
        setEmissionsCategory(event.value);
        console.log('Selected Emissions Category:', event.value);
    };

    const updateSchool = (event) => {
        setSchool(event.value);
        console.log('Selected School Category:', event.value);
      }; 
      
      const updateLocation = (event) => {
        setLocation(event.value);
        console.log('Selected Location Category:', event.value);
      };         

    const updateYear = (event) => {
        const selected = event.target.value;
      
        const updatedYears = [
          ...selected,
          { value: currentYear, label: currentYear },
        ];
      
        const uniqueYears = Array.from(
          new Set(updatedYears.map((year) => year.value))
        ).map((value) => years.find((year) => year.value === value));
      
        setSelectedYears(uniqueYears);
        console.log(uniqueYears);
      };

    const applyFilters = () => {
        const yearsArray = selectedYears.map(item => item.value);
        let seletedFilters = {
            entry_type : emissionsCategory.value,
            site_category_id : school.value,
            site_type_id : location.value,
            years : yearsArray
        }
        setFilters(seletedFilters);

    };
    //functions

    const handleToggleAccordion = (index, relatedTableData, reportData) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
        setTableData(relatedTableData)
        setReportData(reportData)
      };

    const filteredTableYears = tableData.filter((table) =>
        selectedYears.some((year) => year.value === table.year.toString())
      );

    const handleTableUpdate = (relatedTableData, reportType, reportData) => {
        setTableData(relatedTableData)
        setReportType(reportType)
        setReportData(reportData)
        console.log('tracking')
    }

    //render
    return (
        <div style={{margin: '2rem 2rem 0 2rem', padding: '1rem'}}>
        <ComparativeFilter
                year={selectedYears}
                normalizationMetric={normalizationMetric}
                emissionsCategory={emissionsCategory}
                years={years}
                school={school}
                location={location}
                normalizationMetrics={normalizationMetrics}
                emissionsCategories={emissionsCategories}
                schools={schools}
                locations={locations}
                updateYear={updateYear}
                updateNormalizationMetric={updateNormalizationMetric}
                updateEmissionsCategory={updateEmissionsCategory}
                updateSchool={updateSchool}
                updateLocation={updateLocation}
                applyFilters={applyFilters}
                isLoading={loading}
        />          
            <Grid className="display" style={{backgroundColor: '#ffffff', padding: '1rem', margin: '2rem 0', borderRadius: '3px'}}>
                <Cell small={12} large={6} style={{padding: '0 0.5rem'}}>
                    {accordionItems.map((item, index) => (
                        <ComparativeAccordion
                            key={index}
                            title={item.title}
                            content={item.content}
                            kgco2e={item.kgco2e}
                            icon={item.icon}
                            scopeOptions={item.scopeOptions}
                            isOpen={openAccordionIndex === index}
                            onToggle={() => handleToggleAccordion(index, scope1Data, item.reportData)}
                        />
                    ))}
                </Cell>
                <Cell small={12} large={6} style={{padding: '0 0.5rem'}}>
                    {
                        filteredTableYears.map((table) => {
                            return (<ComparativeTable key={table.year} year={table.year} data={table.data} cols={tableHeadings}/>)
                        })
                    }
                    <ComparativePie reportType={reportType} data={reportData}/>
                </Cell>
            </Grid>
        </div>
    );
};

export default EmissionsByType;