import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import EditUserDialog from "./EditUserDialog";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const UserManagementTable = ({ data }) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState({ skip: 0, take: 15 });
  const [selectedUser, setSelectedUser] = useState(null)
  const [userToDelete, setUserToDelete] = useState(null);

  const filteredData = data.filter((item) =>
    Object.values(item)
    .filter(value => typeof value === "string")
    .some((value) => value.toLowerCase().includes(search.toLowerCase())
    )
  );

  const handleEditClick = (user) => {
    setSelectedUser(user);
  };
  
  const handleSaveUser = (updatedUser) => {
    // Update user in state (or send to API)
    console.log("Updated User:", updatedUser);
    setSelectedUser(null); // Close dialog
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user); // Store user info before deleting
  };

  const confirmDelete = () => {
    if (userToDelete) {
    //   setUsers(data.filter((u) => u.id !== userToDelete.id)); // Remove user
      setUserToDelete(null); // Close the dialog
    }
  };
  
  const cancelDelete = () => {
    setUserToDelete(null); // Just close the dialog
  };

  return (
    <div style={{ margin: "auto" }}>
      {/* Global Search Input */}
      <Input
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: "10px", width: "20%" }}
      />

      <Grid
        data={filteredData.slice(page.skip, page.skip + page.take)}
        pageable={{ buttonCount: 5, pageSizes: true }}
        total={filteredData.length}
        skip={page.skip}
        take={page.take}
        onPageChange={(e) => setPage(e.page)}
        sortable
      >
        <GridColumn field="name" title="Name" />
        <GridColumn field="surname" title="Surname" />
        <GridColumn field="email" title="Email" />
        <GridColumn field="role" title="Role" />
        <GridColumn
          title="Actions"
          cell={(props) => (
            <td>
              <Button
                onClick={() => handleEditClick(props.dataItem)}
              >
                Edit
              </Button>
              <Button onClick={() => handleDeleteClick(props.dataItem)} themeColor="error">Delete</Button>

            </td>
          )}
        />
      </Grid>
      {selectedUser && (
      <EditUserDialog
        selectedUser={selectedUser}
        onClose={() => setSelectedUser(null)}
        onSave={handleSaveUser}
      />

        )}
        {userToDelete && (
        <Dialog onClose={cancelDelete}>
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete <strong>{userToDelete.name}</strong>?</p>

            <DialogActionsBar>
            <Button onClick={cancelDelete}>Cancel</Button>
            <Button themeColor="error" onClick={confirmDelete}>Delete</Button>
            </DialogActionsBar>
        </Dialog>
        )}
    </div>
  );
};

export default UserManagementTable;
