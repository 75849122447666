export const levels = [
{ name: "Group/All Sites", value: "group" }, 
{ name: "Subcompany/Division", value: "subcompany" }, 
{ name: "Site", value: "site" }, 
{ name: "Subsite", value: "subsite" }
];

export const scopes = [
//Scope 1
{ name: "Scope 1", value: "scope_1", 
    datasetOption: [
        { name: "Suppliers", value: "suppliers" },
        { name: "Subcontractors", value: "subcontrators" },
        { name: "Local Spend", value: "local_spend" }
    ],
    types: [
        { name: "% of suppliers local to site", value: "percent_suppliers_local" }, 
        { name: "% of subcontractors local to site", value: "percent_subcontractors_local" },
        { name: "£ Local Spend Materials", value: "local_spend_materials" },
        { name: "£ Local Spend Work Packages", value: "local_spend_work_packages" },
        { name: "£ Local Spend Combined", value: "local_spend_combined" },
    ],
    unitOfMeasurement: [
        { name: "Normalised", value: "normalised" },
        { name: "% Numeric", value: "percent_numeric" }, 
        { name: "Absolute Numeric", value: "absolute_numeric" },
        { name: "Absolute No Numeric", value: "absolute_no_numeric" },
    ]
},
//Scope 2
{ name: "Scope 2", value: "scope_2", 
    datasetOption: [
        { name: "GHG Emissions", value: "ghg_emissions" },
        { name: "Waste", value: "waste" },
        { name: "Water", value: "water" },
        { name: "Energy", value: "energy" },
        { name: "Paper", value: "paper" },
    ],
    types: [
        { name: "Total Waste Generated", value: "total_waste_generated" },
    ],
    unitOfMeasurement: [
        { name: "Per 100m2", value: "per_100m2"},
        { name: "Per 100k value of Project", value: "per_100k_value_of_project"},
        { name: "Per £1m Turnover", value: "per_1m_turnover"},
        { name: "Per FTE", value: "per_fte"},
        { name: "Per Journey", value: "per_journey"},
        { name: "Per Student", value: "per_student"},
        { name: "Per Production Tonne", value: "per_production_tonne"},
    ]
},
//Scope 3
{ name: "Scope 3", value: "scope_3", 
    datasetOption: [
        { name: "Workforce Breakdown", value: "workforce_breakdown" },
        { name: "Traning and Development", value: "training_and_development" },
        { name: "Health and Wellbeing", value: "health_and_wellbeing" },
        { name: "Apprenticeships", value: "apprenticeships" },
        { name: "Placements", value: "placements" },
    ],
    types: [
        { name: "Female Workforce", value: "female_workforce" },
        { name: "Workforce Under 25", value: "workforce_under_25" },
    ],
    unitOfMeasurement: [
        { name: "Normalised", value: "normalised" },
        { name: "% Numeric", value: "percent_numeric" }, 
        { name: "Absolute Numeric", value: "absolute_numeric" },
        { name: "Absolute No Numeric", value: "absolute_no_numeric" },
    ]
},
// { name: "Scope 4", value: "scope_4", 
//     datasetOption: [
//         { name: "Community Initiatives", value: "community_initiatives" },
//         { name: "Charitable Donations", value: "charitable_donations" },
//         { name: "Volunteering", value: "volunteering" },
//         { name: "Educational Engagement", value: "educational_engagement" },
//         { name: "Responsible Leadership", value: "responsible_leadership" },
//         { name: "Total Social Value", value: "total_social_value" },
//     ]
// }
];

export const types = [
    // { name: "% of suppliers local to site", value: "percent_suppliers_local" }, 
    // { name: "% of subcontractors local to site", value: "percent_subcontractors_local" },
    // { name: "£ Local Spend Materials", value: "local_spend_materials" },
    // { name: "£ Local Spend Work Packages", value: "local_spend_work_packages" },
    // { name: "£ Local Spend Combined", value: "local_spend_combined" },
    // { name: "Total Waste Generated", value: "total_waste_generated" },
    // { name: "Female Workforce", value: "female_workforce" },
    // { name: "Workforce Under 25", value: "workforce_under_25" },
    { name: "Per 100m2", value: "per_100m2"},
    { name: "Per 100k value of Project", value: "per_100k_value_of_project"},
    { name: "Per £1m Turnover", value: "per_1m_turnover"},
    { name: "Per FTE", value: "per_fte"},
    { name: "Per Journey", value: "per_journey"},
    { name: "Per Student", value: "per_student"},
    { name: "Per Production Tonne", value: "per_production_tonne"},
];

export const units = [
    { name: "Usage/Amount", value: "usage_amount" }, 
    { name: "Tco2e", value: "tco2e" }
];

export const results = [
    { name: "Reduction", value: "reduction" }, 
    { name: "Increase", value: "increase" },
    { name: "Actual Total", value: "actual_total" },
];

export const unitOfMeasurement = [
    { name: "Normalised", value: "normalised" },
    { name: "% Numeric", value: "percent_numeric" }, 
    { name: "Absolute Numeric", value: "absolute_numeric" },
    { name: "Absolute No Numeric", value: "absolute_no_numeric" },
    { name: "Per 100m2", value: "per_100m2"},
    { name: "Per 100k value of Project", value: "per_100k_value_of_project"},
    { name: "Per £1m Turnover", value: "per_1m_turnover"},
    { name: "Per FTE", value: "per_fte"},
    { name: "Per Journey", value: "per_journey"},
    { name: "Per Student", value: "per_student"},
    { name: "Per Production Tonne", value: "per_production_tonne"},
];

export const targetDeadline = [
    {name: "Monthly", value: "monthly"},
    {name: "Quarterly", value: "quarterly"},
    {name: "Annually", value: "annually"},
    {name: "By Target Deadline", value: "by_deadline"},
]

export const sites = [
    {name: "Site 1", value: "site_1"},
    {name: "Site 2", value: "site_2"},
    {name: "Site 3", value: "site_3"},
]