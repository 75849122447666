export const scope1Data = [
    {
        year: 2019,
        data: [
            {
                emission_type: 'Mobile Combustion',
                tco2e: '45.32',
                percent_total_year_co2: '12.5',
                per_FTE: '3.8'
            },
            {
                emission_type: 'Stationary Combustion',
                tco2e: '98.76',
                percent_total_year_co2: '33.2',
                per_FTE: '7.1'
            },
            {
                emission_type: 'Fugitive Emissions',
                tco2e: '25.89',
                percent_total_year_co2: '9.4',
                per_FTE: '2.3'
            },
        ]
    },
    {
        year: 2020,
        data: [
            {
                emission_type: 'Mobile Combustion',
                tco2e: '38.75',
                percent_total_year_co2: '10.8',
                per_FTE: '3.2'
            },
            {
                emission_type: 'Stationary Combustion',
                tco2e: '105.43',
                percent_total_year_co2: '35.7',
                per_FTE: '7.8'
            },
            {
                emission_type: 'Fugitive Emissions',
                tco2e: '30.21',
                percent_total_year_co2: '11.2',
                per_FTE: '2.7'
            },
        ]
    },
    {
        year: 2021,
        data: [
            {
                emission_type: 'Mobile Combustion',
                tco2e: '42.19',
                percent_total_year_co2: '11.5',
                per_FTE: '3.6'
            },
            {
                emission_type: 'Stationary Combustion',
                tco2e: '110.87',
                percent_total_year_co2: '37.1',
                per_FTE: '8.2'
            },
            {
                emission_type: 'Fugitive Emissions',
                tco2e: '27.34',
                percent_total_year_co2: '10.1',
                per_FTE: '2.5'
            },
        ]
    }
]

export const companyOwnedControlledFacilities = [
    {
        year: 2019,
        data: [
            {
                type: 'Burning Oil',
                unit: 'kwh',
                total: '12,500',
                tco2e: '35.78',
                percent_total_year_co2: '8.3',
                per_FTE: '2.1'
            },
            {
                type: 'Diesel',
                unit: 'kwh',
                total: '18,750',
                tco2e: '52.14',
                percent_total_year_co2: '12.1',
                per_FTE: '3.4'
            },
            {
                type: 'Fuel Oil',
                unit: 'kwh',
                total: '22,000',
                tco2e: '61.89',
                percent_total_year_co2: '14.5',
                per_FTE: '4.0'
            },
            {
                type: 'Gas Oil',
                unit: 'kwh',
                total: '15,600',
                tco2e: '43.27',
                percent_total_year_co2: '10.2',
                per_FTE: '2.8'
            },
            {
                type: 'LPG',
                unit: 'kwh',
                total: '9,800',
                tco2e: '27.35',
                percent_total_year_co2: '6.5',
                per_FTE: '1.9'
            },
        ]
        
    },
    {
        year: 2020,
        data: [
            {
                type: 'Burning Oil',
                unit: 'kwh',
                total: '13,200',
                tco2e: '37.45',
                percent_total_year_co2: '8.9',
                per_FTE: '2.3'
            },
            {
                type: 'Diesel',
                unit: 'kwh',
                total: '19,500',
                tco2e: '54.02',
                percent_total_year_co2: '12.7',
                per_FTE: '3.6'
            },
            {
                type: 'Fuel Oil',
                unit: 'kwh',
                total: '21,400',
                tco2e: '60.12',
                percent_total_year_co2: '14.1',
                per_FTE: '3.9'
            },
            {
                type: 'Gas Oil',
                unit: 'kwh',
                total: '16,200',
                tco2e: '45.15',
                percent_total_year_co2: '10.8',
                per_FTE: '2.9'
            },
            {
                type: 'LPG',
                unit: 'kwh',
                total: '10,300',
                tco2e: '28.67',
                percent_total_year_co2: '6.9',
                per_FTE: '2.0'
            },
        ]
        
    }
]

export const EmissionsByKgco2eData = [
    {
        year: '2019',
        energy: '125.6',
        products_and_services: '89.4',
        transport: '210.3',
        waste: '45.8',
    },
    {
        year: '2020',
        energy: '132.8',
        products_and_services: '95.7',
        transport: '198.6',
        waste: '50.2',
    },
    {
        year: '2021',
        energy: '140.2',
        products_and_services: '102.3',
        transport: '215.1',
        waste: '48.6',
    },
    {
        year: '2022',
        energy: '147.5',
        products_and_services: '108.9',
        transport: '225.4',
        waste: '52.1',
    },
]
