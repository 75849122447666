import React from 'react'
import { useState, useEffect, useCallback } from "react";
import {Grid, Cell} from "react-foundation";
import {Grid as DataGrid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import "hammerjs";
import {orderBy} from "@progress/kendo-data-query";
import axiosApi from "../../api/axiosInstance";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import { filterBy } from "@progress/kendo-data-query";
import { Input } from "@progress/kendo-react-inputs";
import { process } from "@progress/kendo-data-query";
import {Link} from "react-router-dom";

const ViewCellLink = (props) => {
    return (
        <td>
            <a href={`/landing/${props.dataItem.id}`}>
                {props.dataItem.id}
            </a>
        </td>
    )
}

const fetchSites = async (page, limit, filters) => {
    try {
        const response = await axiosApi.get(`/sites?page=${page}&limit=${limit}`, {
            params: filters
        });
        return { sites: response.data.data, total: response.data.meta.total };

    } catch (error) {
        console.error("Error fetching sites:", error);
        return { sites: [], total: 0 };
    }
};

const fetchAllSites = async () => {
    try {
        const response = await axiosApi.get(`/sites?no-pagination=1`);
        return response.data.data.map(site => ({
            id: site.id,
            name: site.name,
            site_category: site.site_category,
            site_type: site.site_type,
            full_address: site.full_address,
            reference: site.reference,
            floor_area: site.floor_area,
            location: site.location,
            council: site.council
        }));
    } catch (error) {
        console.error("Error fetching all sites:", error);
        return [];
    }
};


const SitesList = () => {
    const [sites, setSites] = useState([]);
    const [totalSites, setTotalSites] = useState(0);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState({ skip: 0, take: 25 });
    const [filter, setFilter] = useState(null);
    const excelExportRef = React.useRef(null);

    const getSites = useCallback(async () => {
        setLoading(true);
        const page = query.skip / query.take + 1;
        const filters = filter ? filter.filters.reduce((acc, f) => ({ ...acc, [f.field]: f.value }), {}) : {};
        const { sites, total } = await fetchSites(page, query.take, filters);
        setSites(sites.map(site => ({
            id: site.id,
            name: site.name,
            site_category: site.site_category,
            site_type: site.site_type,
            full_address: site.full_address,
            reference: site.reference,
            floor_area: site.floor_area,
            location: site.location,
            council: site.council
        })));
        setTotalSites(total);
        setLoading(false);
    }, [query, filter]);

    useEffect(() => {
        getSites();
    }, [query, filter]);

    const exportToExcel = async () => {
        if (excelExportRef.current) {
            const allSites = await fetchAllSites();
            excelExportRef.current.save(allSites);
        }
    };



    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="dashContainerHeader"><strong>All Sites</strong></div>
                <Grid className="display"
                      style={{
                          height: "800px",
                      }}

                >

                    <Cell small={12} medium={12} large={12}>
                        <div>
                            <Link to={'/add-site'}><button
                                title="Add Site"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            >
                                Add Site
                            </button></Link>
                        </div>


                        <ExcelExport data={sites} ref={excelExportRef} fileName="SitesList.xlsx">
                            <DataGrid
                                data={sites}
                                skip={query.skip}
                                take={query.take}
                                total={totalSites}
                                pageable
                                loading={loading}
                                filterable
                                filter={filter}
                                onFilterChange={e => setFilter(e.filter)}
                                onPageChange={e => setQuery(prev => ({ ...prev, skip: e.page.skip, take: e.page.take }))}
                            >
                                <Toolbar>
                                    <button title="Export Excel" onClick={exportToExcel}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                        Export to Excel
                                    </button>
                                </Toolbar>
                                <GridNoRecords> { totalSites > 0 ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                <Column field="id" title="Id" width="150px" cell={ViewCellLink} />
                                <Column field="name" title="Name" filter="text" />
                                <Column field="site_category" title="Category" filter="text" />
                                <Column field="site_type" title="Type" filter="text" />
                                <Column field="reference" title="Reference" filter="text" />
                                <Column field="floor_area" title="Floor Area" />
                                <Column field="location" title="Location"  filter="text"/>
                                <Column field="council" title="Council" filter="text" />
                                <Column field="full_address" title="Address"  filter="text"/>

                            </DataGrid>
                        </ExcelExport>
                    </Cell>
                </Grid>
            </div>
        </div>
    );

}

export default SitesList;