import React, { useState, useEffect, useMemo } from 'react';
import PredictiveInput from './predictiveInput';
import PredictiveSelector from './PredictiveSelector';
import PredictiveStepper from './PredictiveStepper';
import { rows } from "./data/scope_1_fuel";
import { scope_1_transport } from './data/scope_1_transport';
import { scope_1_waste } from './data/scope_1_waste';
import { Button } from "@progress/kendo-react-buttons";
import { chevronRightIcon, chevronLeftIcon } from "@progress/kendo-svg-icons";
import { scope_2_energy } from './data/scope_2_energy';
import PredictiveResultsTable from './PredictiveResultsTable';

const PredictiveDashboard = () => {
    const [title, setTitle] = useState('')
    const [currentStep, setCurrentStep] = useState('');
    const [formData, setFormData] = useState({});
    const [hasStarted, setHasStarted] = useState(false);
    const [data, setData] = useState([
        {
            id: 'scope_1',
            country: 'Scope 1',
            icon: "https://api.iconify.design/material-symbols:counter-1-outline.svg",
            continent: 'Emissions from sources that your organization owns or controls directly',
            steps: [
                { name: 'Energy', id: 'energy' },
                { name: 'Transport', id: 'transport' },
                // { name: 'Waste', id: 'waste' }
            ]
        },
        {
            id: 'scope_2',
            country: 'Scope 2',
            icon: "https://api.iconify.design/material-symbols:counter-2-outline.svg",
            continent: 'Emissions that your company causes indirectly when the energy it purchases and uses is produced',
            steps: [
                { name: 'Energy', id: 'energy' },
                // { name: 'Transport', id: 'transport' },
                // { name: 'Waste', id: 'waste' }
            ]
        },
        // {
        //     id: 'scope_3',
        //     country: 'Scope 3',
        //     icon: "https://api.iconify.design/material-symbols:counter-3-outline.svg",
        //     continent: 'Emissions your company is indirectly responsible for, up and down the value chain',
        //     steps: [
        //         { name: 'Fuel', id: 'fuel' },
        //         { name: 'Transport', id: 'transport' },
        //         { name: 'Waste', id: 'waste' }
        //     ]
        // }
    ]);

    const [selectedSteps, setSelectedSteps] = useState(() => {
        const initialState = {};
        data.forEach(item => {
            initialState[item.id] = item.steps ? item.steps.map(step => step.id) : [];
        });
        return initialState;
    });

    const stepperSteps = Object.entries(selectedSteps)
    .flatMap(([scope, steps]) =>
        steps.map(step => ({
            id: `${scope}-${step}`, // Unique ID
            label: `${scope.replace('_', ' ')}: ${step.charAt(0).toUpperCase() + step.slice(1)}`,
        }))
    );

    const handleStepChange = (stepId) => {
        setCurrentStep(stepId);
    };

    const handleCheckboxChange = (scopeId, stepId) => {
        setSelectedSteps(prevState => {
            const isChecked = prevState[scopeId].includes(stepId);
            return {
                ...prevState,
                [scopeId]: isChecked
                    ? prevState[scopeId].filter(id => id !== stepId) // Uncheck
                    : [...prevState[scopeId], stepId] // Check
            };
        });
    };

    useEffect(() => {
        if (stepperSteps.length > 0) {
            setCurrentStep(stepperSteps[0].id);
        } else {
            setCurrentStep('');
        }
    }, [selectedSteps]);

    const handleTitle = (e) => {
        setTitle(e.target.value);
      };

    const handleChange = (rowId, fieldId, value) => {
        const newValue = value && typeof value === "object" && "value" in value ? value.value : value;
        console.log(rowId)
        setFormData(prev => ({
            ...prev,
            [rowId]: {
                ...prev[rowId],
                [fieldId]: newValue,
            },
        }));
    };

    const updateOutputValues = (rowId, dummyResponse) => {
        setFormData(prev => ({
            ...prev,
            [rowId]: {
                ...prev[rowId],
                output: dummyResponse.output,
                percentChange: dummyResponse.percentChange,
            },
        }));
    };

    const handleStart = () => {
        setHasStarted(true);
    };

    const handleFinish = () => {
        setCurrentStep('finish')
    }

    const isLastStep = useMemo(() => {
        return stepperSteps.length > 0 && currentStep === stepperSteps[stepperSteps.length - 1].id;
    }, [currentStep, stepperSteps]);

    return (
        <div style={mainStyles}>
            <div style={contentStyle}>
                {
                    !hasStarted ? 
                    <div>
                        <PredictiveSelector data={data} selectedSteps={selectedSteps} onCheckboxChange={handleCheckboxChange} onStart={handleStart} titleValue={title} handleTitle={handleTitle} />
                        {/* <pre>{JSON.stringify(selectedSteps, null, 2)}</pre> */}
                    </div> 
                    : 
                    <div>

                        {
                            currentStep != "finish" ?
                            <div style={{display: "flex", flexDirection: "column"}}>
                            <Button svgIcon={chevronLeftIcon} type="button" style={{ height: "1.5rem", width: "180px", padding: "0 1rem", float: "left", marginBottom: "1rem" }}>Back to the start</Button>
                            <PredictiveStepper steps={stepperSteps} currentStep={currentStep} onStepChange={handleStepChange} />
                            </div> : null
                        }

                        {currentStep === "scope_1-energy" ? 
                        <div>
                            <div style={{padding: "2rem 0 2rem 0", display: "flex", flexDirection: "column"}}>
                                <span style={{fontWeight: "bold", fontSize: "1.5rem"}}>Scope 1: <span style={{fontWeight: "light", fontSize: "1.5rem"}}>Fuel</span></span>
                                <span style={{fontSize: "1rem", paddingTop: "0.5rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</span>
                            </div>
                            <PredictiveInput updateOutputValues={updateOutputValues}  rows={rows} formData={formData} onChange={handleChange} isLastStep={isLastStep} handleFinish={handleFinish} />
                        </div>
                         : null}

                        {currentStep === "scope_1-transport" ? 
                        <div>
                            <div style={{padding: "2rem 0", display: "flex", flexDirection: "column"}}>
                                <span style={{fontWeight: "bold", fontSize: "1.5rem"}}>Scope 1: <span style={{fontWeight: "light", fontSize: "1.5rem"}}>Transport</span></span>
                                <span style={{fontSize: "1rem", paddingTop: "0.5rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</span>
                            </div>
                            <PredictiveInput updateOutputValues={updateOutputValues} rows={scope_1_transport} formData={formData} onChange={handleChange} isLastStep={isLastStep} handleFinish={handleFinish} />
                        </div>
                         : null}

                        {currentStep === "scope_2-energy" ? 
                        <div>
                            <div style={{padding: "2rem 0", display: "flex", flexDirection: "column"}}>
                                <span style={{fontWeight: "bold", fontSize: "1.5rem"}}>Scope 1: <span style={{fontWeight: "light", fontSize: "1.5rem"}}>Waste</span></span>
                                <span style={{fontSize: "1rem", paddingTop: "0.5rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</span>
                            </div>
                            <PredictiveInput updateOutputValues={updateOutputValues} rows={scope_2_energy} formData={formData} onChange={handleChange} isLastStep={isLastStep} handleFinish={handleFinish} />
                        </div>
                         : null}
                    </div>
                } 
                {
                    currentStep === "finish" ? 
                    // <PredictiveResults/>
                    <PredictiveResultsTable formData={formData} title={title}/>
                    : null
                }
            </div>
        </div>
    );
};

export default PredictiveDashboard;

// Styles
const mainStyles = {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center"
};
const contentStyle = { width: "75%" };
