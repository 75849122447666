import {useEffect, useState} from "react";
import {fetchSchoolCategories} from "../../services/SiteService";
import {useSchoolCategoriesDropDown} from "./useSchoolCategoriesDropDown";

export const years = Array.from(
    { length: new Date().getFullYear() - 2019 },
    (_, i) => ({
        label: `${2019 + i}`,
        value: 2019 + i
    })
);

export const normalizationMetrics = [
    { value: 'number_of_staff', label: 'Number of Staff' },
    { value: 'per_100m2', label: 'Per 100m2' },
    { value: 'per_1m', label: 'Per £1m' },
    { value: 'per_100k', label: 'Per £100,000' },
];

export const emissionsCategories = [
    { value: 'all', label: 'All' },
    { value: 'energy', label: 'Energy (Build Environment)' },
    { value: 'transport', label: 'Transport' },
    { value: 'waste', label: 'Waste' },
   // { value: 'land_use', label: 'Land Use' },
];
/*
export const schools = [
    { value: 'all', label: 'All' },
    { value: 'nursery', label: 'Nursery' },
    { value: 'primary', label: 'Primary' },
    { value: 'post_primary', label: 'Post Primary' },
    { value: 'special_ed', label: 'Special Ed' },
];
*/

//export const schools = fetchSchoolCategories();

//export const schools = useSchoolCategoriesDropDown()

export const locations = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Urban' },
    { value: 2, label: 'Rural' },
]; 