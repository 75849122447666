import { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {Button} from '@progress/kendo-react-buttons';
import { pencilIcon, fileCsvIcon, chevronLeftIcon } from '@progress/kendo-svg-icons';
import { useNavigate } from "react-router-dom";


const AllPredictiveReports = () => {
    const navigate = useNavigate();
    const [reports, setReports] = useState([
        { id: 1, name: "Report 1" },
        { id: 2, name: "Report 2" },
        { id: 3, name: "Report 3" },
        { id: 4, name: "Report 4" },
        { id: 5, name: "Report 5" },
      ]);

      const handleEdit = () => {
        navigate(`/predictive-dashboard`); // For now this is a dummy function
      };

    return (
        <div style={mainStyles}>
            <div style={contentStyle}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 0.5rem 0" }}> 
                        <span style={{ fontSize: "1.25rem", fontWeight: 800 }}>All Predictive Reports</span>
                        <div style={{ display: "flex", flexDirection: "row" }} >
                        <Button 
                        // onClick={onStart} 
                        svgIcon={chevronLeftIcon} 
                        type="button" 
                        style={{ height: "3rem", padding: "0 1rem", float: "right" }}
                        >
                        Create New Predictive Report
                        </Button>
                    </div>
                </div>

                <div style={{ width: "100%", marginTop: "1rem" }}>
                    <Grid
                        data={reports}
                        style={{ width: "100%" }}
                        resizable
                    >
                        <GridColumn field="name" title="Report Name" />

                        <GridColumn
                        title="Actions"
                        width="150px"
                        cell={() => (
                            <td style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                            <Button themeColor="primary" size="small" onClick={() => handleEdit()}>Edit</Button>
                            <Button themeColor="error" size="small">Delete</Button>
                            </td>
                        )}
                        />
                    </Grid>
                </div>

            </div>
        </div>
    )
}

export default AllPredictiveReports

// Styles
const mainStyles = {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center"
};
const contentStyle = { width: "75%" };