

import * as React from 'react';
import { ExpansionPanel, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Reveal } from '@progress/kendo-react-animation';
import { Button } from "@progress/kendo-react-buttons";
import { chevronRightIcon, chevronLeftIcon } from "@progress/kendo-svg-icons";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
const PredictiveSelector = ({ data, selectedSteps, onCheckboxChange, onStart, titleValue, handleTitle }) => {
  const [expanded, setExpanded] = React.useState('scope_1');
  const areAllScopesEmpty = Object.values(selectedSteps).every(arr => arr.length === 0);
  return (
    <div className="wrapper">
        <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
            <div style={{ display: "flex", flexDirection: "column", padding: "0 0 0.5rem 0" }}> 
                <span style={{ fontSize: "1rem", fontWeight: 800 }}>Report Name</span>
                <span style={{ fontSize: "1rem", fontStyle: "italic", marginTop: "0.5rem" }}>Give your Predictive Analysis Report a memorable name</span>  
            </div>
            <Input
                value={titleValue}
                size="large"
                style={{ width: "100%", height: "3rem", marginBottom: "1rem" }}
                onChange={handleTitle}
            />
            <div style={{ display: "flex", flexDirection: "column", padding: "0 0 0.5rem 0" }}> 
                <span style={{ fontSize: "1rem", fontWeight: 800 }}>Scope Selection</span>
                <span style={{ fontSize: "1rem", fontStyle: "italic", marginTop: "0.5rem" }}>Select from the menu below which sections you wish to cover in your report. If you change your mind you can come back here and add a section back that you missed.</span>  
            </div>
        </div>

        {data.map(item => (
            <ExpansionPanel
                key={item.id}
                title={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <img style={{width: '2.5rem', marginRight: ''}} src={item.icon}/>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <span style={{fontSize: "1.1rem", textTransform: "capitalize", fontWeight: "bold"}}>{item.country}</span>
                        <span style={{fontSize: "0.8rem", paddingTop: "0.25rem", textTransform: "capitalize", color: "black"}}>{item.continent}</span>
                        </div>
                    </div>
                }
                expanded={expanded === item.id}
                tabIndex={0}
                onAction={() => setExpanded(expanded === item.id ? '' : item.id)}
            >
                <Reveal>
                    {expanded === item.id && (
                        <ExpansionPanelContent>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {item.steps && item.steps.map((step, stepIndex) => (
                                    <div
                                        key={step.id}
                                        style={{
                                            padding: '0.5rem 1rem',
                                            backgroundColor: stepIndex % 2 === 0 ? "#f0f0f0" : "#ffffff",
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span>{step.name}</span>
                                        <Checkbox
                                            checked={selectedSteps[item.id]?.includes(step.id)}
                                            onChange={() => onCheckboxChange(item.id, step.id)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
        ))}
        <Button disabled={!titleValue.trim() || areAllScopesEmpty} onClick={onStart} svgIcon={chevronRightIcon} type="button" style={{ height: "3rem", padding: "0 1rem", float: "right", marginTop: "1rem" }}>Get Started</Button>
    </div>
);
};
export default PredictiveSelector;