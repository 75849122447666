import React, {useEffect, useState} from 'react';
import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from '@progress/kendo-react-charts';
import { DropDownList, AutoComplete } from "@progress/kendo-react-dropdowns"
import {Grid as DataGrid, GridColumn as Column, GridNoRecords, GridToolbar, GridColumn} from "@progress/kendo-react-grid";
import {Grid, Cell} from "react-foundation";
import { energyOptions, productOptions, transportOptions, wasteOptions } from './kgco2eValues';
// import { wasteOptions } from './kgco2eWasteTypes';
import {Button} from '@progress/kendo-react-buttons';


const EmissionsByKgco2eReport = (props) => {
    const reportSummary = props.reportData;
    const [energy, setEnergy] = useState({ value: 'Energy', label: 'Energy' });
    const [product, setProduct] = useState({ value: 'All', label: 'Products and Services' });
    const [transport, setTransport] = useState({ value: 'Transport', label: 'Transport' });
    const [waste, setWaste] = useState({ value: 'Waste', label: 'Waste' });

    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const [lineData, setLineData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [lineCategories, setLineCategories] = useState([]);
    const updateEnergy = (event) => {
        setEnergy(event.value);
        console.log('Selected', event.value);
    };
    const updateProduct = (event) => {
        setProduct(event.value);
        console.log('Selected', event.value);
    };
    const updateTransport = (event) => {
        setTransport(event.value);
        console.log('Selected', event.value);
    };
    const updateWaste = (event) => {
        setWaste(event.value);
        console.log('Selected', event.value);
    };

    const applyFilters = () => {

        let seletedFilters = {
            energy : energy.value,
            transport : transport.value,
            waste : waste.value,
        }
        setFilters(seletedFilters);
    };

    useEffect(() => {
        setIsLoading(true);
        const generateChartData = (reportSummary) => {
            if (!reportSummary || Object.keys(reportSummary).length === 0) {
                return ;
            }

            // Extract years from formattedReport and sort them
            const lineCategories = Object.keys(reportSummary)
                .map(year => parseInt(year))
                .sort((a, b) => a - b)
                .map(String); // Convert back to strings

            console.log(filters);

            const filterCategories = [
                { name: "Energy", value: filters.energy || "Energy" },
                { name: "Transport", value: filters.transport || "Transport" },
                { name: "Waste", value: filters.waste || "Waste"  }
            ];

            const lineData = filterCategories.map(category => ({
                name: category.value,
                data: lineCategories.map(year =>
                    reportSummary[year]?.[category.value]?.total_kgco2e ||
                    reportSummary[year]?.[category.name]?.categories?.[category.value]?.total_kgco2e ||
                    0
                )
            }));

            const tableData = lineCategories.map(year => {
                let row = { year }; // Start with the year field

                filterCategories.forEach(category => {
                    row[category.value] = reportSummary[year]?.[category.value]?.total_kgco2e ||
                        reportSummary[year]?.[category.name]?.categories?.[category.value]?.total_kgco2e ||
                        0;
                });

                return row;
            });

            setLineData(lineData);
            setTableData(tableData);
            setLineCategories(lineCategories);
            setIsLoading(false);

           // return {lineData, lineCategories, tableData};
        };
        generateChartData(reportSummary);

    },[reportSummary,filters]);




// Usage Example


    return (
        <div style={{padding: '0 1rem'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0'}}>
                    <span>Emissions By KgCo2e</span>
                    <div style={{padding: '1rem 0'}}>
                        <DropDownList
                            data={energyOptions}
                            onChange={updateEnergy}
                            value={energy}
                            textField="label"
                            dataValueField="value"
                            placeholder="Energy"
                            style={{ width: '200px', margin: '0 0.25rem' }}
                        />
                        <DropDownList
                            data={productOptions}
                            onChange={updateProduct}
                            value={product}
                            textField="label"
                            dataValueField="value"
                            placeholder="Products and Services"
                            style={{ width: '200px', margin: '0 0.25rem' }}
                        />
                        <DropDownList
                            data={transportOptions}
                            onChange={updateTransport}
                            value={transport}
                            textField="label"
                            dataValueField="value"
                            placeholder="Transport"
                            style={{ width: '200px', margin: '0 0.25rem' }}
                        />
                        <DropDownList
                            data={wasteOptions}
                            onChange={updateWaste}
                            value={waste}
                            textField="label"
                            dataValueField="value"
                            placeholder="Transport"
                            style={{ width: '200px', margin: '0 0.25rem' }}
                        />
                        <Button
                        type="button"
                        style={{ height: "32px", padding: "0 1rem", float: "right", margin: '0 0.5rem' }}
                        onClick={applyFilters}
                        disabled={isLoading}
                        >
                        Apply
                        </Button>
                    </div>
                </div>
                <Grid>
                    <Cell large={8}>
                    <Chart style={{ height: 350 }}>
                        <ChartTitle text="" />
                        <ChartLegend position="bottom" orientation="horizontal" />
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem categories={lineCategories} />
                        </ChartCategoryAxis>
                        <ChartSeries>
                        {lineData.map((series, index) => (
                            <ChartSeriesItem
                                key={index}
                                type="line"
                                tooltip={{ visible: true }}
                                name={series.name}
                                data={series.data}
                            />
                        ))}
                        </ChartSeries>
                    </Chart>
                    </Cell>
                    <Cell large={4}>
                        <DataGrid data={tableData}>
                            <Column field="year" title="Year"/>
                            { lineData.map(category => (
                                <Column key={category.name} field={category.name} title={category.name} />
                            )) }
                        </DataGrid>
                    </Cell>
                </Grid>
        </div>
    )
}

export default EmissionsByKgco2eReport