import {Button} from "@progress/kendo-react-buttons";
import {Loader} from "@progress/kendo-react-indicators";
import React, {useState} from "react";
import axiosApi from "../../api/axiosInstance";

const FileImportForm = (props) => {
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const handleFileSelect = (event) => {
        console.log(event.target.files);
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.size > 2 * 1024 * 1024) {
            setMessage("File size should not exceed 2MB.");
            setFile(null);
            return;
        }
        setMessage("");
        setFile(selectedFile);
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file) {
            setMessage("Please select a file first.");
            return;
        }

        setLoading(true);
        setMessage("");
        const formData = new FormData();
        formData.append("csv_import", file);

        try {
            const response = await axiosApi.postForm(props.upload_api_url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setMessage(response.data.message || "File uploaded successfully.");
            setFile(null)
            console.log(response);
        } catch (error) {
            console.log(error.response.data);
            let errorMessage = "Error uploading file ";
            if (error.response.data) {
                for (const field in error.response.data) {
                    error.response.data[field].forEach(message => {
                        errorMessage += message + " ";
                    });
                }
            }
            setMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleUpload}>
            <p>Please Upload a CSV file</p>

            <hr/>
            <input type="file" name="csv_import" accept=".csv" onChange={handleFileSelect} className="block w-full" />
            <hr/>
            {message && <div className="k-text-error">{message} <hr/></div>}
            {loading ? <Loader size="big" />  :
                <Button type="submit" primary={true} disabled={loading} className="w-full">
                    Upload
                </Button> }
        </form>
    )
}
export default FileImportForm