import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { chevronRightIcon, chevronLeftIcon } from "@progress/kendo-svg-icons";
import { NumericTextBox } from "@progress/kendo-react-inputs";


const PredictiveInput = ({ rows, formData, onChange, updateOutputValues, isLastStep, handleFinish }) => {
  
  const handleApiCall = async (rowId) => {
    const rowData = formData[rowId] || [];
    console.log(`Fetching data for: ${rowId}`, rowData);
    console.log(rowData[0])

    const dummyResponse = {
      output: (Math.random() * 100).toFixed(2), 
      percentChange: (Math.random() * 10).toFixed(2),
    };

    updateOutputValues(rowId, dummyResponse);
  };

  return (
    <div>
      {rows.map((row) => (
        <div key={row.rowName} style={{ marginBottom: "0.5rem" }}>
          {/* Row Title & Description */}
          <div style={{ display: "flex", flexDirection: "column", padding: "0 0 0.5rem 0" }}> 
            <span style={{ fontSize: "1rem", fontWeight: 800 }}>{row.rowName}</span>
            <span style={{ fontSize: "1rem", fontStyle: "italic", marginTop: "0.5rem" }}>{row.rowDescription}</span>  
          </div>

          {/* Inputs */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%" }}>
            <div style={{ display: "flex", flex: 1, gap: "10px" }}>
              {row.fields.map((field) => (
                <div key={field.id} style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
                  <Label>{field.name}</Label>
                  {field.isDropdown ? (
                    <DropDownList
                      data={field.dropdownValues || []}
                      textField="name"
                      dataItemKey="value"
                      value={field.dropdownValues?.find(option => option.value === formData?.[row.rowId]?.[field.id]) || null}
                      onChange={(e) => onChange(row.rowId, field.id, e.value)}
                      placeholder={field.placeholder}
                      style={{ width: "100%", height: "3rem" }}
                    />
                  ) : (
                    <Input
                      value={formData?.[row.rowId]?.[field.id] || ""}
                      onChange={(e) => onChange(row.rowId, field.id, e.target.value)}
                      placeholder={field.placeholder}
                      size="large"
                      style={{ width: "100%", height: "3rem" }}
                    />
                  )}
                </div>
              ))}
            </div>

            {/* Conversion Button */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50px", marginTop: "1.5rem" }}>
              <Button 
                svgIcon={chevronRightIcon} 
                type="button" 
                style={{ borderRadius: 100, width: "3rem", height: "3rem" }} 
                onClick={() => handleApiCall(row.rowId)} 
              />
            </div>

            {/* Output Fields */}
            <div style={{ display: "flex", flex: 1, gap: "10px" }}>
              <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Label>Output</Label>
                <Input disabled value={formData?.[row.rowId]?.output || ""} placeholder="Output (calculated)" style={{ width: "100%", height: "3rem" }} />
              </div>
              <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Label>% Change</Label>
                <Input disabled value={formData?.[row.rowId]?.percentChange || ""} placeholder="% Change (calculated)" style={{ width: "100%", height: "3rem" }} />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", padding: "0.5rem 0 0.5rem 0" }}>
            <span style={{ fontSize: "0.75rem", fontStyle: "italic", textAlign: "right" }}>{row.calculationMethod}</span>  
          </div>
        </div>
      ))}

      {
        isLastStep ? 
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
        <Button svgIcon={chevronRightIcon} onClick={handleFinish} type="button" style={{ height: "3rem", padding: "0 1rem" }}>Finish</Button>
      </div> : null
      }
      
      {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
    </div>
  );
};

export default PredictiveInput;
