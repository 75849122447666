import axiosApi from "../api/axiosInstance";
import {useEffect, useState} from "react";

const GhgSummaryReport = async (params) => {

    if (!params.entry_type || !["energy", "waste", "transport"].includes(params.entry_type)) {
        console.warn("Invalid or missing 'entry_type' parameter in GhgSummaryReport:");
        return null; // Return null for invalid or missing type
    }
    if (!params.year) {
        console.warn("Invalid or missing 'year' parameter in GhgSummaryReport:");
        return null; // Return null for invalid or missing type
    }

    let entryType = "";
    let reportGroups = ["category_types"];
    let outputs = ["e_tco2e", "e_kgco2e"];

    switch (params.entry_type) {
        case "energy":
            outputs = [...outputs, "t_kwh_gross_cv", "c_kwh_gross_cv", "t_l", "c_l", "el_tnd_kgco2e", "el_tnd_tco2e"];
            entryType = "PillarTwoSiteEnergyMeterEntry";
            break;

        case "waste":
            entryType = "PillarTwoSiteWasteEntryMaterial";
            outputs.push("t_t");
            break;

        case "transport":
            entryType = "PillarTwoSiteTransport";
            outputs.push("t_mi");
            break;
        default:
            break;
    }

    if (params.report_groups) {
        reportGroups = params.report_groups;
    }

    const post_params = {
        company_id: 1,
        pillar: 2,
        summary_type: "financial-year",
        entry_type: entryType,
        dates: [{
            date_from: `${params.year - 1}-04-01`,
            date_to: `${params.year}-03-31`
        }],
        report_groups: reportGroups,
        outputs: outputs
    };

    if (params.site_type_id) {
        post_params.site_type_id = params.site_type_id;
    }

    if (params.site_category_id) {
        post_params.site_category_id = params.site_category_id;
    }

    try {
        const response = await axiosApi.post("ghg-summaries/report", post_params);
        if (response.status === 200) {
            return response.data.reports;
        }
    } catch (error) {
        console.error("Error fetching GHG Summary Report:", error);
        return null;
    }
}

export default GhgSummaryReport;
