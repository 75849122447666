import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import TargetsChart from "./TargetsChart";
import { SvgIcon } from '@progress/kendo-react-common';
import { filePdfIcon } from '@progress/kendo-svg-icons';

const initialData = [
  { id: 1, targetName: "Energy Reduction", targetDesc: "Reduce energy consumption", scope: "Global", level: "Corporate", site: "HQ", datasetOption: "Annual", type: "Efficiency", unit: "%", targetResult: "10", change: "-2", unitOfMeasurement: "%", baselineYear: "2020", interval: "Yearly", goalDate: "2030" },
  { id: 2, targetName: "Water Usage", targetDesc: "Lower water usage", scope: "Regional", level: "Division", site: "Plant A", datasetOption: "Monthly", type: "Sustainability", unit: "m3", targetResult: "5000", change: "-500", unitOfMeasurement: "m3", baselineYear: "2021", interval: "Monthly", goalDate: "2025" },
  { id: 3, targetName: "GHG Emissions", targetDesc: "Reduce CO2 emissions", scope: "Global", level: "Corporate", site: "All Sites", datasetOption: "Annual", type: "Carbon Footprint", unit: "tons", targetResult: "2000", change: "-200", unitOfMeasurement: "tons", baselineYear: "2019", interval: "Yearly", goalDate: "2030" },
  { id: 4, targetName: "Recycling Rate", targetDesc: "Increase recycling efforts", scope: "Local", level: "Plant", site: "Plant B", datasetOption: "Quarterly", type: "Waste Management", unit: "%", targetResult: "75", change: "+5", unitOfMeasurement: "%", baselineYear: "2022", interval: "Quarterly", goalDate: "2026" },
  { id: 5, targetName: "Renewable Energy", targetDesc: "Increase use of renewables", scope: "Global", level: "Corporate", site: "All Sites", datasetOption: "Annual", type: "Energy", unit: "%", targetResult: "50", change: "+10", unitOfMeasurement: "%", baselineYear: "2018", interval: "Yearly", goalDate: "2040" },
  { id: 6, targetName: "Employee Safety", targetDesc: "Reduce workplace incidents", scope: "Regional", level: "Division", site: "Offices", datasetOption: "Monthly", type: "Health & Safety", unit: "Incidents", targetResult: "5", change: "-1", unitOfMeasurement: "Cases", baselineYear: "2021", interval: "Monthly", goalDate: "2025" },
  { id: 7, targetName: "Customer Satisfaction", targetDesc: "Improve NPS score", scope: "Global", level: "Corporate", site: "All Markets", datasetOption: "Annual", type: "Performance", unit: "NPS", targetResult: "80", change: "+5", unitOfMeasurement: "Points", baselineYear: "2020", interval: "Yearly", goalDate: "2025" },
  { id: 8, targetName: "Production Efficiency", targetDesc: "Optimize production process", scope: "Plant", level: "Operations", site: "Factory C", datasetOption: "Weekly", type: "Manufacturing", unit: "Hours", targetResult: "500", change: "-50", unitOfMeasurement: "Hours", baselineYear: "2022", interval: "Weekly", goalDate: "2027" },
  { id: 9, targetName: "Waste Reduction", targetDesc: "Minimize industrial waste", scope: "Local", level: "Plant", site: "Plant D", datasetOption: "Monthly", type: "Waste Management", unit: "kg", targetResult: "2000", change: "-100", unitOfMeasurement: "kg", baselineYear: "2021", interval: "Monthly", goalDate: "2026" },
  { id: 10, targetName: "Supply Chain Efficiency", targetDesc: "Improve delivery times", scope: "Global", level: "Logistics", site: "All Warehouses", datasetOption: "Quarterly", type: "Operations", unit: "Days", targetResult: "2", change: "-1", unitOfMeasurement: "Days", baselineYear: "2020", interval: "Quarterly", goalDate: "2024" }
];

const TargetsDashboard = () => {
  const [data, setData] = useState(initialData);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [selectedTarget, setSelectedTarget] = useState(null)

  const onEdit = (user) => {
    setSelectedTarget(user);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    if (selectedTarget) {
      navigate("/new-target", { state: { target: selectedTarget } });
    } else {
      console.error("No target selected");
    }
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const CellWithHide = (props) => {
    return props.dataItem[props.field] ? <td>{props.dataItem[props.field]}</td> : <td />;
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val?.toString().toLowerCase().includes(search.toLowerCase())
    )
  );


  //chart data object, for now it just creates a random spread of numbers
  const chartData = Array.from({ length: 26 }, (_, i) => {
    const year = 2025 + i;
    const progress = i / 25;
  
    return {
      year,
      pledged_reductions: Math.max(500000 * (1 - 2 * progress), 0),
      interim_target: 500000 - Math.floor(300000 * progress),
      reduced_emissions: 500000 - Math.floor(300000 * progress) + (progress > 0.5 ? 100000 : 0),
    };
  });

  const ActionCell = (props) => (
    <td>
      <Button onClick={() => { 
          onEdit(props.dataItem); 
          handleClick(); 
        }} primary>
          Edit
      </Button>
      <Button onClick={() => handleDelete(props.dataItem.id)} themeColor="error">
        Delete
      </Button>
    </td>
  );


  return (
    <div style={mainStyles}>
      <div style={contentStyle}>
        <div style={headerStyle}>
          <span>All Targets</span>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Input placeholder="Search..." value={search} onChange={handleSearch} style={{  }} />
            <Button primary onClick={handleClick}>
              Go to Target Page
            </Button>
          </div>
        </div>
        <Grid
          data={process(filteredData, { skip: page.skip, take: page.take })}
          pageable
          total={filteredData.length}
          skip={page.skip}
          take={page.take}
          onPageChange={(e) => setPage(e.page)}
        >
          <GridColumn field="targetName" title="Target Name" cell={CellWithHide} />
          <GridColumn field="targetDesc" title="Target Description" cell={CellWithHide} />
          <GridColumn field="scope" title="Scope" cell={CellWithHide} />
          <GridColumn field="level" title="Level" cell={CellWithHide} />
          <GridColumn field="site" title="Site" cell={CellWithHide} />
          <GridColumn field="datasetOption" title="Dataset" cell={CellWithHide} />
          <GridColumn field="type" title="Type/KPI" cell={CellWithHide} />
          <GridColumn field="unit" title="Unit" cell={CellWithHide} />
          <GridColumn field="targetResult" title="Target Result" cell={CellWithHide} />
          <GridColumn cell={ActionCell} title="Actions" />
        </Grid>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
            gap: "0.5rem",
            marginTop: "1rem",
          }}
          >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "1rem 0" }}>
              <span style={{ fontWeight: "bold" }}>Targets Report 1</span>
              <Button
                svgIcon={filePdfIcon}
                type="button"
              />
            </div>
            <TargetsChart data={chartData}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetsDashboard;

const mainStyles = { marginTop: "2rem", display: "flex", justifyContent: "center" };
const contentStyle = { width: "95%" };
const headerStyle = { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem" };