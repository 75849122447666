import { Chart, ChartTitle, ChartLegend, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, } from "@progress/kendo-react-charts";
import "hammerjs";

const Kgco2eYoY = () => {
  // Dummy data
  const kgco2eCategories = ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036"];
  const kgco2eDataSeries = [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40];

  return (
    <Chart style={{ height: "400px", margin: "0.5rem 0" }}>
      <ChartTitle text="Kgco2e Output Year over Year" />
      <ChartLegend position="top" />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem categories={kgco2eCategories} title={{ text: "Years" }} />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem title={{ text: "KgCO₂e Output (tons)" }} />
      </ChartValueAxis>
      <ChartSeries>
        <ChartSeriesItem 
          type="line" 
          data={kgco2eDataSeries} 
          name="Output"
          markers={{ visible: true }}
        />
      </ChartSeries>
    </Chart>
  );
};

export default Kgco2eYoY;