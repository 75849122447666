import React from "react";
import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem } from "@progress/kendo-react-charts";
import "@progress/kendo-theme-default/dist/all.css";

const TargetsChart = ({ data }) => {
    return (
        <Chart>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem 
            categories={data.map((item) => item.year)} 
            title={{ text: "Year" }} 
            labels={{ step: 5 }}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem type="line" data={data.map((item) => item.interim_target)} name="Interim Target" />
          <ChartSeriesItem type="line" data={data.map((item) => item.reduced_emissions)} name="Reduced Emissions" />
          <ChartSeriesItem type="line" data={data.map((item) => item.pledged_reductions)} name="Pledged Reductions" />
        </ChartSeries>
      </Chart>
      );
};

export default TargetsChart;