import {Link} from "react-router-dom";
import {Cell} from "react-foundation";
import * as React from "react";
const ManageNavbar = () => {

    return (
        <Cell small={12} medium={2} large={2}>
            <div className="energyNavigation">
                <h5>Imports</h5>
                <ul>
                    <li>
                        <Link to={'/manage/import/energymeters' } className="nav-link">EnergyMeters</Link>
                    </li>
                    <li>
                        <Link to={'/manage/import/sites' } className="nav-link">Sites</Link>
                    </li>
                    <li>
                        <Link to={'/manage/import/energyentries' } className="nav-link">EnergyEntries</Link>
                    </li>
                    <li>
                        <Link to={'/manage/import/transport' } className="nav-link">Transport</Link>
                    </li>
                    <li>
                        <Link to={'/manage/import/waste' } className="nav-link">Waste</Link>
                    </li>
                </ul>
                <hr/>
                <h5>Exports</h5>
                <ul>
                    <li>
                        <Link to={'/manage/export/energy' } className="nav-link">Energy</Link>
                    </li>
                    <li>
                        <Link to={'/manage/export/transport' } className="nav-link">Transport</Link>
                    </li>
                    <li>
                        <Link to={'/manage/export/waste' } className="nav-link">Waste</Link>
                    </li>
                </ul>
            </div>
        </Cell>
    )
}

export default ManageNavbar