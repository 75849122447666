import { Grid, GridColumn } from "@progress/kendo-react-grid";
import Kgco2eYoY from "./kgco2eYoY";
import CostYoY from "./costYoY";
import {Button} from '@progress/kendo-react-buttons';
import { italicIcon, fileCsvIcon, filePdfIcon } from '@progress/kendo-svg-icons';
import { useNavigate } from "react-router-dom";

const PredictiveResultsTable = ({ formData, title }) => {
  const navigate = useNavigate();
  const gridData = Object.keys(formData).map((key, index) => ({
    id: index + 1,
    name: key.replace(/_/g, " "),
    data: formData[key].data,
    output: formData[key].output,
    percentChange: formData[key].percentChange,
  }));

  const handleReturn = () => {
    navigate(`/predictive-reports`); // For now this is a dummy function
    console.log('beans')
  };

  return (
    <div>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 0.5rem 0" }}> 
        <div style={{display: "flex", flexDirection: "column"}}>
        <Button themeColor="primary" size="small" onClick={() => handleReturn()}>Return to All Reports</Button>
        <span style={{ fontSize: "1.25rem", fontWeight: 800 }}>{title}</span>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }} >
          <Button
              className="k-toolbar-button action-button"
              svgIcon={fileCsvIcon}
              title="Download CSV"
          />
          <Button
              className="k-toolbar-button action-button"
              svgIcon={filePdfIcon}
              title="Download PDF"
          />
        </div>
    </div>
    <Grid
      data={gridData}
      style={{ width: "100%", marginTop: "20px" }}
      pageable={true}
      sortable={true}
    >
      <GridColumn field="name" title="Category" width="250px" />
      <GridColumn field="data" title="Input Data" width="150px" />
      <GridColumn field="output" title="Output Value" width="150px" />
      <GridColumn field="percentChange" title="% Change" width="150px" />
    </Grid>
    <div style={{ display: "flex", flexDirection: "column" }} >
    <Kgco2eYoY/>
    <CostYoY/>
    </div>
    </div>
  );
};

export default PredictiveResultsTable;