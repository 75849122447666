export const scope_2_energy = [
    {
        rowName: "Renewable Energy Tariff",
        rowId: "renewable_energy_tariff",
        rowDescription: "Change to Renewable Energy Tariff",
        calculationMethod: "Method: known electric usage with 0 conversion factor:  If we use example of 100000 kwh per year usage on brown energy, then co2e produced is dervied from yearly electric conversion factor.  If switching to a green tariff, we can default to 100% for demo, and allow the user to add the correct % of green tariff energy.  If 50%, then 50000 is brown energy, 50000 is green.  The green is determined as 0 kgco2e, which leaves the remaining 50000 to be multiplied by the yearly conversion factor for electric.  To determine the saving, deduct the 50000 co2e value from the 100000 co2e value, the brown energy is predicted, while the remaining is green and is a saving.",
        fields: [
          {
            name: "% Green Tariff",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
          },
        ],
      },
      {
        rowName: "Replace Flourescent Lights",
        rowId: "replace_flourescent_lights",
        rowDescription: "Replace all flourescent lighting with LED equivalents. Where appropriate, light fittings with integral presence detection should be prioritised.",
        calculationMethod: "Method: if we use the basis that one light fixture exists for every m5m, then we make the assumption that this translates to one light fixture for every 5 m2, then we can use the building foot print divided by 5 to determine the total number of light fixtures.  We also use the assumption that lighting is 25% of the electricity used in the school.  Therefore we first determine the number light fixtures, 250m2 footprint will contain 50 light fixtures, we make the initial asusmption that all 50 will be replaced but allow the user to change this down.  We also need to determine the 25% of electric used, so 100000 kwh will result in 25000 kwh alone for lighting.  We then tke the 25000 kwh and divide back to per light usage, in this case 500 kwh per fixture, if the new fixture redice by 70% on electric per unit then we redice this down to 150 kwh per unit with a saving of 350 kwh, again we can apply the number of units to be change to multiple back the predicted usage and saving, 350 * 50 = 17500 saved, and 150 * 50 = 7500 predicted.  THen we apply electric yearly conversion rates to define co2e predicted and saved.  Cost per unit is multiplied out by the number of units replaced.  If a value is a decimal for units, use basic mathetical concepts to round up or down.",
        fields: [
          {
            name: "% Lights Changed",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
          },
        ],
      },
      {
        rowName: "Install 10kW Solar PV System",
        rowId: "install_solar_system",
        rowDescription: "Install 10kW Solar PV System",
        calculationMethod: "Method: apply as a single solar units and allow the user to determine the applicable number to be applied overall.  Use the base that 1 unit generates 8000 kwh per year, and multiply up by number of units.  3 would save 24000 kwh / annum, etc.  This will carrry 0 co2e, therefore you will need to determine the total electric consumption for a year, and deduct the applicable number of kwh saved to get applicable co2e and co2e saved values.  Cost will be generated from number of units * uit cost.",
        fields: [
          {
            name: "Systems Installed",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
          },
        ],
      },
      {
        rowName: "Install Energy Monitoring System",
        rowId: "install_energy_monitoring_system",
        rowDescription: "Install Energy Monitoring & Targeting (eM&T) system and provide staff with training on its operation.",
        calculationMethod: "Method: get totals for all energy used within the calendar year, and determine the value of the 5% reduction, each energy source should be calculated independently, with the 95% bein ussed to determine the predicted co2e value, and the 5% being used to determine the reduction co2e value.  Cost will need to be added for the monitoring system, and added as a singular cost.",
        fields: [
          {
            name: "Systems Installed",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
          },
        ],
      }, 
      {
        rowName: "Energy Awareness Training",
        rowId: "energy_awareness_training",
        rowDescription: "Provide a staff with basic energy awareness training.",
        calculationMethod: "Method: applicable to heating sources, reduction of co2e and usage by 10%, determine the total gas (or oil) used for a calendar year, get the 10% value to determine the saving and apply the yearly conversion rate for natural gas to get the saving, and apply the yearly converion for natural gas to the 95% to get the predicted co2e value.  Cost to be added as a singular value per school, at company level it should be multiplied up the number of schools.",
        fields: [
          {
            name: "% Staff Trained",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
          },
        ],
      }, 
      {
        rowName: "Presence Detecting Sensors",
        rowId: "presence_detecting_sensors",
        rowDescription: "Replace existing lighting presence dectecting sensors, with combined presence/daylight sensors to ensure that  lights are held off when sufficent daylight is available.",
        calculationMethod: "Method: Determine the 50% value of the calendar year electricity usage which will represent the lighting usage value in kwh.  For example if 10000 kwh, take the 50% for lighting to act as 5000 kwh.  It is this 5000 kwh that the 10% reduction is applied to, getting 10% of the 5000 kwh = 500 kwh saving.  Applying the yearly co2e conversion for electric to the 4500 kwh to ge the predicted co2e, and to the 500 kwh to provide the savings co2e.  This is linked to the 5m2 light example, again we set the initial value as all lights, but allow the user to reduce this and recalculate the 10% saving as a percentage per light value.  Cost will be applied to the same 5m2 light count.",
        fields: [
          {
            name: "% Lights w/Sensors",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
          },
        ],
      }, 
      {
        rowName: "Reduce Phantom Consumption",
        rowId: "reduce_phantom_consumption",
        rowDescription: "switch off devices to reduce phantom consumption",
        calculationMethod: "Method: Vampire devices, at a school we can make the assumption that devices (computers etc) will be respoinsible for most electrical usage throughout a day, but are reduced to a lower setting before and after school hours.  On average the ddevices will be used for 8 hours in a day, and should be switched off outside of usage hours.",
        fields: [
          {
            name: "% Devices Turned Off",
            id: "data",
            isDropdown: false,
            dropdownValues: null,
            placeholder: "",
          },
        ],
      },
]