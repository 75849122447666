import React, { useState } from "react";
import UserManagementTable from "./UserManagementTable";
import { Button } from "@progress/kendo-react-buttons";
import userData from "./userData";
import AddUserDialog from "./AddUserDialog";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";



const UserManagement = () => {
    const [users, setUsers] = useState(userData);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);


    const handleAddUser = (newUser) => {
        const newUserWithId = { id: users.length + 1, ...newUser };
        setUsers([...users, newUserWithId]);
        setShowSuccessDialog(true);
        setTimeout(() => setShowSuccessDialog(false), 2000);
      };

    return (
        <div style={mainStyle}>
            <div style={contentStyle}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", paddingBottom: "1rem" }}>
                    <span>User Management</span>
                    <Button themeColor="primary" onClick={() => setShowAddDialog(true)}>Add New User</Button>
                </div>
                <UserManagementTable data={users}/>

                {/* Add User Dialog */}
                {showAddDialog && <AddUserDialog onClose={() => setShowAddDialog(false)} onAddUser={handleAddUser} />}

                {/* Success Dialog */}
                {showSuccessDialog && (
                    <Dialog onClose={() => setShowSuccessDialog(false)}>
                    <p>User added successfully!</p>
                    <DialogActionsBar>
                        <Button onClick={() => setShowSuccessDialog(false)}>OK</Button>
                    </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        </div>
    )
}

export default UserManagement

// Styles
const mainStyle = {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center"
};
const contentStyle = { width: "75%", padding: "2rem", background: "white" };