import axiosApi from "../api/axiosInstance";
export const fetchSchoolCategories = async () => {
    try {
        const response = await axiosApi.get('site-categories');
        return response.data.data.map(category => ({
            label: category.name,
            value: category.id
        }));
    } catch (error) {
        console.error("Error fetching school categories:", error);
        return [];
    }
};