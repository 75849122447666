import {Button} from "@progress/kendo-react-buttons";
import {Loader} from "@progress/kendo-react-indicators";
import React, {useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {requiredValidator} from "../form/validators";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {FormatEnergyEntries} from "../Energy/EnergyHelper";
import {ExcelExport, ExcelExportColumn} from "@progress/kendo-react-excel-export";
import {FormatEntries} from "../Transport/TransportHelper";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";

const energy_types = [
    { value: 1, label: 'Electricity' },
    { value: 2, label: 'Natural Gas' },
    { value: 15, label: 'Burning Oil' },
    { value: 16, label: 'Gas oil' }
];

const ExportForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const isEnergy = props.export_type === 'energy';
    const excelExportRef =React.useRef(null);
    const [entries, setEntries] = useState(['']);
    const type = props.export_type;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Extracts YYYY-MM-DD
    };
    const handleSubmit = async (dataItem) => {

        setLoading(true);
        setMessage("");
        const formattedData = {
            ...dataItem,
            start_date: formatDate(dataItem.start_date),
            end_date: formatDate(dataItem.end_date),
           // energy_type_id: dataItem.energy_type_id?.value, // Send only category ID
            company_id: 1
        };

        try {
            const all_entries = await axiosApi.get(
                props.api_url,
                {
                    params: formattedData
                }).then(function (response) {
                alert("response");
                let data = response['data']['data'];
                if(type === 'energy') {
                    let result = FormatEnergyEntries(data, false);
                    return result['energy_table_data'];
                } else if(type === 'transport') {
                    let list = data['list'] ?? [];
                    return FormatEntries(list,false);
                }
                //console.log(entries);
               // return entries;
            });
            if (excelExportRef.current) {
                //const allSites = await fetchAllSites();
                excelExportRef.current.save(all_entries);
            }
           // excelExportRef.current.save(all_entries);
        } catch (error) {
            console.log(error);
            setMessage("Error Exporting file.");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {isEnergy ? ( <ExcelExport data={entries} ref={excelExportRef} fileName={"Company"+type+"ExportList.xlsx"}>
                <ExcelExportColumn  field="Meter" title="Meter" width="150" />
                <ExcelExportColumn  field="Site" title="School" width="150" />
                <ExcelExportColumn  field="EnergyType" title="Energy Type" />
                <ExcelExportColumn  field="Date" title="Date" />
                <ExcelExportColumn  field="Amount" title="Amount" format="{0:n2}"/>
                <ExcelExportColumn  field="CO2eTotal" title="CO2e Total" format="{0:n2}"/>
                <ExcelExportColumn  field="Kwh" title="kWh" format="{0:n2}"/>
                <ExcelExportColumn  field="Kitchen" title="Kitchen" format="{0:n2}"/>
                <ExcelExportColumn  field="School" title="School" format="{0:n2}"/>
                <ExcelExportColumn  field="General" title="General" format="{0:n2}"/>
            </ExcelExport> ) : ( <ExcelExport data={entries} ref={excelExportRef} fileName={"Company"+type+"ExportList.xlsx"}>
                    <ExcelExportColumn field="Registration" title="Registration" />
                    <ExcelExportColumn field="Make" title="Make" />
                    <ExcelExportColumn field="Model" title="Model" />
                    <ExcelExportColumn field="Type" title="Type" />
                    <ExcelExportColumn field="Miles" title="Miles" />
                    <ExcelExportColumn field="CO2eTotal" title="CO2eTotal" format="{0:n2}" />
                    <ExcelExportColumn field="tCO2eTotal" title="tCO2eTotal" format="{0:n4}" />
                    <ExcelExportColumn field="From" title="From" />
                    <ExcelExportColumn field="To" title="to" />
                    <ExcelExportColumn field="Date" title="Date" />
            </ExcelExport> )}

                <Form
                onSubmit={handleSubmit}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <Field
                                name="start_date"
                                component={DatePicker}
                                width="100%"
                                format="dd-MM-yyyy"
                                id="startDate" max={new Date()}
                                placeholder="Start Date"
                                label="Start Date"
                                validator={requiredValidator}
                            />
                            <Field
                                component={DatePicker}
                                width="100%"
                                format="dd-MM-yyyy"
                                name="end_date"
                                id="endDate"
                                placeholder="End Date"
                                label="End Date"
                                max={new Date()}
                                validator={requiredValidator}
                            />

                            {isEnergy ? (
                                <Field
                                    name="energy_type_id"
                                    component={DropDownList}
                                    data={energy_types}
                                    textField="label"
                                    dataItemKey="value"
                                    label="Energy Type"
                                    validator={requiredValidator}
                                />
                            ) : ''}
                            <hr/>
                            {message && <div className="k-text-error">{message} <hr/></div>}
                            {loading ? <Loader size="medium" />  :
                                <Button type="submit" primary={true} className="w-full">
                                    Export
                                </Button> }
                        </fieldset>
                    </FormElement>
                )}
            />
        </div>
    )
}
export default ExportForm