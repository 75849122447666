import {Grid, Cell} from "react-foundation";
import React from "react";
import ManageNavbar from "./Navbar";
import ExportForm from "./ExportForm";
// import * as React from "@types/react";

const TransportExport = () => {

    return (
        <Grid>
            <ManageNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Transport</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={6} large={6}>
                                <div className="quickSelectTab">
                                    <h4>Transport Export</h4>
                                    <hr/>
                                    <ExportForm api_url="/pillar/2/site-transports" type="transport"  />
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default TransportExport;