export const energyOptions = [
    { value: 'All', label: 'Energy'},
    { value: 'Electricity', label: 'Electricity (Grid)' },
    { value: 'Electricity(T&D)', label: 'electricity t&d' },
    { value: 'Natural Gas', label: 'Natural Gas' },
    { value: 'Gas Oil', label: 'Gas Oil' },
    { value: 'Burning Oil', label: 'Kerosene' },
];
export const productOptions = [
    { value: 'All', label: 'Products and Services' },
];
export const transportOptions = [
    { value: 'Transport', label: 'Transport' },
    //{ value: 'petrol', label: 'Petrol' },
    //{ value: 'diesel', label: 'Diesel' },
    //{ value: 'biofuel', label: 'Biofuel' },
];

export const wasteOptions = [
    { value: 'Waste', label: 'Waste' }
    //{ value: 'waste_process_emissions', label: 'Waste Process Emissions' },
   // { value: 'waste_transport', label: 'Waste Transport' },
]
