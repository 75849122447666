import React, { useState } from "react";
import { Stepper } from '@progress/kendo-react-layout';
import { cartIcon, dollarIcon, eyeIcon, mapMarkerIcon, trackChangesAcceptIcon } from '@progress/kendo-svg-icons';


const PredictiveStepper = ({ steps, currentStep, onStepChange }) => {
    const handleChange = e => {
        const selectedStep = steps[e.value]; // Get the selected step object
        if (selectedStep) {
            onStepChange(selectedStep.id); // Pass step ID instead of index
        }
    };

    // Find index of the current step
    const stepIndex = steps.findIndex(step => step.id === currentStep);

    return <Stepper value={stepIndex} onChange={handleChange} items={steps} />;
};

export default PredictiveStepper;
