import React, { useEffect, useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { scopes, levels, types, units, results, unitOfMeasurement, targetDeadline, sites } from "./TargetsInputValues";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const TargetsForm = ({ }) => {
  const location = useLocation();
  const target = location.state?.target;
  const [formData, setFormData] = useState({
    targetName: target.targetName || "",
    targetDesc: target.targetDesc || "",
    level: target.level || "",
    site: target.site || "",
    scope: target.scope || "",
    datasetOption: target.datasetOption || "",
    type: target.type || "",
    unit: target.unit || "",
    targetResult: target.targetResult || "",
    change: target.change || "",
    unitOfMeasurement: target.unitOfMeasurement || "",
    baselineYear: null,
    interval: target.interval || "",
    goalDate: target.goalDate || "", 
  });


  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const selectedScope = scopes.find(scope => scope.value === formData.scope.value);
  const availableDatasetOptions = selectedScope ? selectedScope.datasetOption : [];
  const availableTypeOptions = selectedScope ? selectedScope.types : [];
  const availableUnitOfMeasurementOptions = selectedScope ? selectedScope.unitOfMeasurement : [];

  useEffect(() => {
    console.log(formData)
  }, [formData])
  
  const navigate = useNavigate();
  const onSubmit = async (formData) => {
    navigate("/targets-dashboard");
  };

  return (

    <div style={mainStyles}>
      <div style={contentStyle}>

        <div style={{padding: "2rem 0 2rem 0", display: "flex", flexDirection: "column"}}>
            <span style={{fontWeight: "bold", fontSize: "1.5rem"}}>Create a New Target</span>
            <span style={{fontSize: "1rem", paddingTop: "0.5rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</span>
        </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>

        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>

          <div style={{padding: "2rem 0 1rem 0", display: "flex", flex: 2, flexDirection: "column"}}>
              <span style={{fontWeight: "bold", fontSize: "1rem"}}>General Information about your Target</span>
              <span style={{fontSize: "0.9rem", paddingTop: "0.5rem"}}>Give your new target some information that you’ll understand when visiting in the future. Adding a descriptive title and description will help give the data behind it some context.</span>
          </div>

          <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
              <Label>Target Name</Label>
              <Input size="large" value={formData.targetName} onChange={(e) => handleChange("targetName", e.target.value)} />
            </div>

            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
              <Label>Level</Label>
              <DropDownList data={levels} textField="name" dataItemKey="value" value={formData.level} onChange={(e) => handleChange("level", e.value)} />
            </div>

            {
            formData.level.value !== "group" ?
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
              <Label>Site</Label>
              <DropDownList data={sites} textField="name" dataItemKey="value" value={formData.site} onChange={(e) => handleChange("site", e.value)} />
            </div> : null
            }
          </div>
          
        </div>

        <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
          <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
              <Label>Target Description</Label>
              <Input size="large" value={formData.targetDesc} onChange={(e) => handleChange("targetDesc", e.target.value)} style={{ height: "100px" }} />
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>

          <div style={{padding: "2rem 0 1rem 0", display: "flex", flex: 2, flexDirection: "column"}}>
              <span style={{fontWeight: "bold", fontSize: "1rem"}}>Scope & Dataset</span>
              <span style={{fontSize: "0.9rem", paddingTop: "0.5rem"}}>Select the Scope and its subsequent dataset you wish to target. For Example, you want to target <strong>LPG</strong> in <strong>Scope 1</strong>, if you select Scope 1 in the first field, you will find LPG in the second field.</span>
          </div>

          <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
              <Label>Scope</Label>
              <DropDownList data={scopes} textField="name" dataItemKey="value" value={formData.scope} onChange={(e) => handleChange("scope", e.value)} />
            </div>

            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
              <Label>Dataset</Label>
              <DropDownList data={availableDatasetOptions} textField="name" dataItemKey="value" value={formData.datasetOption} onChange={(e) => handleChange("datasetOption", e.value)} />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap", flexDirection: "column" }}>

        <div style={{padding: "2rem 0 1rem 0", display: "flex", flex: 2, flexDirection: "column"}}>
              <span style={{fontWeight: "bold", fontSize: "1rem"}}>Details about your Dataset</span>
              <span style={{fontSize: "0.9rem", paddingTop: "0.5rem"}}>Add some more details relating to your dataset here.</span>
          </div>

          <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
                <Label>Type</Label>
                <DropDownList data={availableTypeOptions} textField="name" dataItemKey="value" value={formData.type} onChange={(e) => handleChange("type", e.value)} />
            </div>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
                <Label>Unit</Label>
                <DropDownList data={units} textField="name" dataItemKey="value" value={formData.unit} onChange={(e) => handleChange("unit", e.value)} />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap", flexDirection: "column" }}>

        <div style={{padding: "2rem 0 1rem 0", display: "flex", flex: 2, flexDirection: "column"}}>
              <span style={{fontWeight: "bold", fontSize: "1rem"}}>Target Goal Specification</span>
              <span style={{fontSize: "0.9rem", paddingTop: "0.5rem"}}>Specify the goal of this target you are setting, for example, you are looking a <strong>Reduction</strong> of <strong>12.25%</strong> in <strong>Type Selected</strong> by <strong>Specific Date</strong></span>
          </div>

          <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
                <Label>Target Result</Label>
                <DropDownList data={results} textField="name" dataItemKey="value" value={formData.targetResult} onChange={(e) => handleChange("targetResult", e.value)} />
            </div>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
                <Label>Amount Change</Label>
                <Input size="large" value={formData.change} onChange={(e) => handleChange("change", e.target.value)} />
            </div>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
                <Label>Unit of Measurement</Label>
                <DropDownList data={availableUnitOfMeasurementOptions} textField="name" dataItemKey="value" value={formData.unitOfMeasurement} onChange={(e) => handleChange("unitOfMeasurement", e.value)} />
            </div>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
              <Label>Baseline year</Label>
              <DatePicker 
              value={formData.baselineYear} 
              onChange={(e) => handleChange("baselineYear", e.value)} 
              depth="year" 
              defaultView="year" 
              format="yyyy" 
              />
            </div>
            <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
                <Label>Target Interval</Label>
                <DropDownList data={targetDeadline} textField="name" dataItemKey="value" value={formData.interval} onChange={(e) => handleChange("interval", e.value)} />
            </div>
            {
              formData.interval.value === "by_deadline" ? 
              <div style={{ flex: 1, minWidth: "50px", display: "flex", flexDirection: "column" }}>
              <Label>Date</Label>
              <DatePicker value={formData.goalDate} onChange={(e) => handleChange("goalDate", e.value)} />
              </div> : null
            }
          </div>
        </div>

        <Button primary onClick={() => onSubmit(formData)}>Submit</Button>
      </div>
      </div>
    </div>
  );
};

export default TargetsForm;

// Styles
const mainStyles = {
  marginTop: "2rem",
  display: "flex",
  justifyContent: "center"
};
const contentStyle = { width: "65%" };