import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import React from "react";
import {parseNumber} from "../form/format-helper";



export function FormatWasteEntries(entries, chart = true) {

    // console.log(entries['monthly']);


    const waste_series = [];
    const waste_tonnage_by_month = [];
    const waste_process_emissions = [];
    const waste_transport_emissions = [];
    const total_summary = [];
    const total_summary_process_emissions = [];
    const total_summary_waste_transport_emissions = [];
    const waste_data_table = [];
    const waste_types_chart = [];
    const waste_destinations_chart = [];
    let result = [];

    // Format Summary overall
    let waste_overall = entries['overall'];
    console.log(waste_overall);

    total_summary['amount'] = waste_overall['weights'][0] ? parseNumber(waste_overall['weights'][0]['amount']) : 0;
    total_summary_process_emissions['total_reuse'] =   waste_overall['weights'][0] ?  parseNumber(waste_overall['weights'][0]['total_reuse_kgco2e']) : 0;
    total_summary_process_emissions['total_incinerate'] =  waste_overall['weights'][0] ?  parseNumber(waste_overall['weights'][0]['total_incinerate_kgco2e']) : 0;

    if (waste_overall['waste_emissions']) {
        for (let i = 0; i < waste_overall['waste_emissions'].length; i++) {
            let entry = waste_overall['waste_emissions'][i];
            if(entry['measurement']['name'] == 'kgCO2e') {
                total_summary['kgco2e'] = parseNumber(entry['amount']);
            } else if(entry['measurement']['name'] == 'kgCO2') {
                total_summary['kgco2'] = parseNumber(entry['amount']);
            }
        }
    }
    if(waste_overall['distances']) {
        for (let i = 0; i < waste_overall['distances'].length; i++) {
            let entry = waste_overall['distances'][i];
            if(entry['measurement']['name'] == 'Mile') {
                total_summary['miles'] = parseNumber(entry['amount']);
            } else if(entry['measurement']['name'] == 'Kilometre') {
                total_summary['kilometres'] = parseNumber(entry['amount']);
            }
        }
    }

    //Format Waste by Material Chart
    if(entries['waste_materials']) {
        let waste_types = entries['waste_materials'];
        let chart_data = [];
        for (let i = 0; i < waste_types.length; i++) {

            let entry = waste_types[i];
            chart_data.push({
                value: entry['weights'][0]['amount'],
                category: entry['name']
            });
        }
        waste_types_chart.push({
            name: 'Amount Tonnes',
            data: chart_data
        })
    }

    //Format Waste by Destinations Chart
    if(entries['waste_destinations']) {
        let waste_destinations = entries['waste_destinations'];
        let chart_data = [];
        for (let i = 0; i < waste_destinations.length; i++) {

            let entry = waste_destinations[i];
            chart_data.push({
                value: entry['weights'][0]['amount'],
                category: entry['name']
            });
        }
        waste_destinations_chart.push({
            name: 'Amount in Tonnes',
            data: chart_data
        })
    }

    //waste Charts by month By Tonne and by Recycle use
    if (entries['monthly']) {
        let waste_monthly = entries['monthly'];
        let months = [];
        let chart_data = [];
        let reuse_kgco2e = [];
        let incinerate_kgco2e = [];

        for (let i = 0; i < waste_monthly.length; i++) {
            let entry = waste_monthly[i];
            let color = '#37b400'; // 1 is for estimate 0 for actual

            let month = entry['date']
            month = months.includes(month) ? month + '-0' + Math.floor(Math.random() * 9) : month;
            months.push(month)
            chart_data.push({
                value: entry['weights'][0]['amount'],
                color: color,
                category: month
            });
            reuse_kgco2e.push({
                value: entry['weights'][0]['reuse_kgco2e'],
                // color: color,
                category: month
            });
            incinerate_kgco2e.push({
                value: entry['weights'][0]['incinerate_kgco2e'],
                // color: color_2,
                category: month
            });
        }
        waste_tonnage_by_month.push({
            data: chart_data
        })
        waste_process_emissions.push({
            name: 'Recycle KGCO2e',
            data: reuse_kgco2e,
        })
        waste_process_emissions.push({
            name: 'Incinerate KGCO2e',
            data: incinerate_kgco2e,
        })
    }





        // if (summary['waste_transport_emissions']) {
        //     let chart_data = [];
        //     for (let i = 0; i < summary['waste_transport_emissions'].length; i++) {
        //         let entry = summary['by_day_api'][i];
        //         let color = (entry['bill_estimate_flag'] === 1) ? '#ff4e50' : '#37b400'; // 1 is for estimate 0 for actual
        //         chart_data.push({
        //             value: entry['amount'],
        //             category: entry['added_on'],
        //         });
        //     }
        //     waste_transport_emissions.push({
        //         data: chart_data
        //     })
        //     //console.log(water_series_by_code);
        // }
        // console.log(summary);

        // }
        //
        // if (summary['process_emissions']['overall']) {
        //
        //     for (let codeName in summary['process_emissions']['overall']) {
        //         let codeSummary = [];
        //         let entry = summary['process_emissions']['overall'][codeName];
        //         let emissions = getEmissions(entry['ghg_emissions'], 'total_amount');
        //         codeSummary = {
        //             name: codeName,
        //             amount: Math.round(entry['total_amount'] * 100) / 100,
        //             kgco2: emissions['kgco2'],
        //             kgco2e: emissions['kgco2e'],
        //             tco2e: emissions['tco2e']
        //         };
        //         total_summary_process_emissions.push(codeSummary);
        //     }//console.log(totalSummary);
        // }
        //
        // if (summary['waste_transport_emissions']['overall']) {
        //
        //     for (let codeName in summary['waste_transport_emissions']['overall']) {
        //         let codeSummary = [];
        //         let entry = summary['waste_transport_emissions']['overall'][codeName];
        //         let emissions = getEmissions(entry['ghg_emissions'], 'total_amount');
        //         codeSummary = {
        //             name: codeName,
        //             amount: Math.round(entry['total_amount'] * 100) / 100,
        //             kgco2: emissions['kgco2'],
        //             kgco2e: emissions['kgco2e'],
        //             tco2e: emissions['tco2e']
        //         };
        //         total_summary_waste_transport_emissions.push(codeSummary);
        //     }//console.log(totalSummary);
        // }



    //charts
    result['waste_tonnage_by_month'] = waste_tonnage_by_month;
    result['waste_process_emissions'] = waste_process_emissions;
    result['waste_transport_emissions'] = waste_transport_emissions;
    result['total_summary'] = total_summary;
    result['total_summary_process_emissions'] = total_summary_process_emissions;
    result['total_summary_waste_transport_emissions'] = total_summary_waste_transport_emissions;
    result['waste_material_chart'] = waste_types_chart;
    result['waste_destinations_chart'] = waste_destinations_chart;

    //console.log(result);
    return result;
// return chart ? waste_series : wasteEntries;
}